define("nfe-customer-console-v2/adapters/usage-report", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UsageReportAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", '{+host}/reports/{reportType}/{year}');

      _defineProperty(this, "host", _environment.default.APP.API_URL);
    }

    getUsageReport(accountId, reportType, year) {
      const url = super.buildURL('usage-report', null, {
        accountId,
        reportType,
        year
      }, 'query');
      return super.ajax(url, 'GET', {
        headers: this.headersApiKey
      });
    }

  }

  _exports.default = UsageReportAdapter;
  ;
});