define("nfe-customer-console-v2/components/company-v2-form/component", ["exports", "nfe-customer-console-v2/models/enums", "nfe-customer-console-v2/helpers/cnpj-validator", "ember-concurrency"], function (_exports, _enums, _cnpjValidator, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    company: Ember.inject.service(),
    taxRegimes: _enums.default.taxRegimes,
    autoFillDisabled: false,
    selectTaxRegime: Ember.computed('model.taxRegime', function () {
      const value = this.get('model.taxRegime');
      return _enums.default.taxRegimes.filter(v => v.code === value)[0];
    }),
    addressAutoFill: Ember.computed(function () {
      return Ember.isPresent(this.model.address.postalCode);
    }),
    getCnpj: Ember.observer('model.federalTaxNumber', async function () {
      const taxNumber = this.get('model.federalTaxNumber');
      if ((0, _cnpjValidator.cnpjValidator)(taxNumber) === false) return;
      const companyAdapter = this.store.adapterFor('company');
      const company = await companyAdapter.getCompanyByFederalTaxNumber(taxNumber);
      const {
        name,
        tradeName,
        address
      } = company;
      this.model.setProperties({
        name,
        tradeName,
        address
      });
    }),
    autoFill: (0, _emberConcurrency.task)(function* () {
      if (this.model.validations.attrs.federalTaxNumber.isInvalid || this.autoFillDisabled) return this.model;
      const federalTaxNumber = this.model.federalTaxNumber.replace(/\D/, '');

      try {
        const company = yield this.company.getCompanyByFederalTaxNumber(federalTaxNumber);
        if (!company) return;
        this.model.eachAttribute(name => {
          if (name != 'postalCode') this.set(`model.${name}`, address[name]);
        });
        if ((0, _cnpjValidator.cnpjValidator)(taxNumber) === false) return;
        const {
          name,
          tradeName,
          legalNature,
          address
        } = company;

        const filteredLegalNature = _enums.default.legalNatures.filter(v => v.id === legalNature.code)[0];

        this.model.setProperties({
          name,
          tradeName,
          address,
          legalNature: filteredLegalNature && filteredLegalNature.hasOwnProperty("code") ? filteredLegalNature.code : null
        });
      } catch (error) {
        return;
      }
    })
  });

  _exports.default = _default;
});