define("nfe-customer-console-v2/translations/pt-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accessKey": "Chave de acesso",
    "account": {
      "accessKeys": {
        "clientSecrets": {
          "itemClientId": "Client ID",
          "itemDescription": "Descrição",
          "itemExpiration": "Expiração",
          "itemHead": "ID do cliente e chaves",
          "itemHeadExpanded": "{clientName} detalhes do cliente",
          "itemSecret": "Chave",
          "itemType": "Tipo",
          "secretsList": "Lists de chaves",
          "title": "Clientes e Chaves"
        },
        "header": "Chaves de Acesso",
        "invoice": "Chave de acesso para ",
        "knowMore": "Saiba mais sobre todas as nossas integrações.",
        "listHeader": {
          "key": "Chave de Acesso",
          "title": "Lista de API Keys (Chaves de Acesso)",
          "type": "Tipo"
        },
        "text": "Utilizamos as <strong>Chaves de Acesso (API Keys)</strong> para autenticar e autorizar todas as chamadas realizadas em nossa plataforma. Mantenha-as em sigilo para sua segurança e nunca repasse a terceiros.",
        "title": "Chaves de Acesso e Clientes",
        "types": {
          "data": "Essa Chave de Acesso é exclusiva para a consulta de dados",
          "issueInvoices": "Essa Chave de Acesso é exclusiva para a emissão de notas fiscais"
        }
      },
      "changePassword": {
        "confirmNewPassword": "Confirme a Nova Senha",
        "logout": "Sair",
        "newPassword": "Nova Senha",
        "oldPassword": "Senha Atual",
        "text": "Para mudar sua senha você precisa preencher os campos abaixo.",
        "title": "Alterar Senha"
      },
      "environmentDescriptions": {
        "Development": "Sua conta está operando em modo de teste. <strong>Fale com nossa equipe para ativar o modo de produção.</strong>",
        "Production": "Sua conta está operando no modo de produção, todos os recursos da plataforma estão ativados."
      },
      "export": {
        "action": "Exportar",
        "emptyTable": {
          "text": "Não encontramos nenhuma exportação. Você pode exportar um relatório do uso da sua conta ou um lote de NFS-e nas listagem de notas da sua empresa.",
          "title": "Nenhuma exportação encontrada"
        },
        "gotToExports": "Ir para exportações",
        "modal": {
          "error": "Erro ao solicitar exportação. Tente novamente mais tarde.",
          "filters": {
            "period": "Período",
            "status": "Status da nota fiscal"
          },
          "invalidPeriod": "Período máximo de 31 dias.",
          "success": "Sua exportação estará disponível para download em breve. Acesse a página de exportações para mais detalhes.",
          "text": "Deseja prosseguir com a exportação?"
        },
        "resource": {
          "account-usage": "Uso da conta",
          "company-product-invoice-analytical-csv": "Relatório de NF-e - CSV",
          "company-product-invoice-pdf": "Lote de NF-e - PDF",
          "company-product-invoice-xml": "Lote de NF-e - XML",
          "company-service-invoice-analytical-csv": "Relatório de NFS-e - CSV",
          "company-service-invoice-pdf": "Lote de NFS-e - PDF",
          "company-service-invoice-xml": "Lote de NFS-e - XML"
        },
        "status": {
          "Compressing": "Comprimindo",
          "Done": "Concluído",
          "Exporting": "Exportando",
          "Failed": "Falha",
          "None": "Nenhum",
          "Pending": "Pendente",
          "Running": "Em andamento"
        },
        "table": {
          "date": "Período",
          "download": "Baixar exportação",
          "timeSpent": "Tempo gasto",
          "totalLines": "N°. de linhas"
        },
        "text": "Aqui você verá uma lista com todos os relatórios de uso solicitados e poderá baixá-los.",
        "title": "Exportações"
      },
      "header": "Conta",
      "information": {
        "footer": "Detalhes da conta",
        "header": "Informações da conta",
        "subscriptionFooter": "Detalhes da assinatura",
        "subscriptionHeader": "Informações da assinatura",
        "title": "Dados Básicos"
      },
      "invoices": {
        "downloadEInvoice": "Baixar Nota Fiscal",
        "downloadEInvoiceXML": "Baixar Nota Fiscal XML",
        "downloadInvoice": "Baixar Fatura",
        "emptyTable": {
          "text": "",
          "title": "Nenhuma fatura encontrada"
        },
        "footer": "Detalhes de faturas da assinatura",
        "header": "Faturas",
        "paymentNow": "Pagar Agora",
        "status": {
          "AwaitingAccounting": "Aguardando contabilidade",
          "AwaitingEInvoice": "Aguardando Nota Fiscal",
          "AwaitingPayment": "Aguardando pagamento",
          "AwaitingPaymentMethod": "Aguardando método de pagamento",
          "Cancelled": "Cancelado",
          "Paid": "Pago",
          "PartiallyPaid": "Parcialmente pago",
          "PaymentDone": "Pagamento feito",
          "Pending": "Pendente",
          "Refunded": "Estornado"
        },
        "table": {
          "createdOn": "Competência em",
          "dueOn": "Vencimento em",
          "invoiceCode": "Cód. Fatura"
        },
        "text": "Nesse painel você verá seu histórico de faturas, onde poderá visualizar os detalhes e baixar o PDF de cada uma delas, além da nota fiscal."
      },
      "linkNewVersion": {
        "header": "Contratos e faturas (beta)",
        "link": "Clique aqui e acesse.",
        "text": "Conheça nossa nova plataforma de conta, onde você poderá ver detalhes do seu contrato, faturas e formas de pagamento."
      },
      "paymentMethods": {
        "addPaymentMethod": "Adicionar método de pagamento",
        "emptyList": {
          "text": "Não encontramos nenhum cartão de crédito cadastrado em sua conta. Clique no botão abaixo para cadastrar seu cartão.",
          "title": "Nenhum cartão de crédito encontrado"
        },
        "header": "Métodos de pagamento",
        "text": "Acesse esse painel para gerenciar seus métodos de pagamentos. Aqui você poderá adicionar um novo ou remover um já existente."
      },
      "preInvoices": {
        "detailedUsage": "Ver uso detalhado",
        "header": "Pré-Faturas",
        "text": "Aqui você verá seu histórico de pré-faturas, onde poderá visualizar os detalhes, baixar o PDF e o relatório de uso de cada uma delas."
      },
      "providers": {
        "actionBtn": "{action, select, activate {Ativar} deactivate {Desativar}}",
        "dialog": {
          "content": "Esta ação irá {action, select, activate {ativar} deactivate {desativar}} a funcionalidade de ",
          "title": "Deseja realmente {action, select, activate {ativar} deactivate {desativar}} esta funcionalidade?",
          "warningMessage": "Ao ativar esse serviço você poderá utilizar gratuitamente por 15 dias, após esse periodo poderá gerar cobranças"
        },
        "header": "Funcionalidades",
        "tableBody": {
          "AzHub.Account": "Contas",
          "AzHub.Billing": "Cobrança",
          "AzHub.Billing.Order": "Cobrança - Pré-Fatura",
          "AzHub.Usage": "Uso da Platataforma",
          "DFeTech.Company": "Múltiplas Empresas",
          "DFeTech.ConsumerInvoice": "Emissão de Nota Fiscal de Consumidor (NFC-e)",
          "DFeTech.Mail": "Envio de E-mail",
          "DFeTech.ProductInvoice": "Emissão de Nota Fiscal de Produto (NF-e)",
          "DFeTech.ProductInvoice.Inbound": "NF-e distribuição",
          "DFeTech.ServiceInvoice": "Emissão de Nota Fiscal de Serviço (NFS-e)",
          "DFeTech.ServiceInvoice.Batch": "Emissão de até 500 Notas Fiscais de Serviço (NFS-e) em Lote pela plataforma",
          "DFeTech.TransportationInvoice.Inbound": "CT-e distribuição",
          "DFeTech.WebHook": "Webhooks para Emissão de Notas",
          "DFeTech.WebHook.Encryption": "Encripitação de Webhook",
          "DataTech.Batch": "Consulta de Notas Fiscais de Produto (NF-e) em Lote - DANFE e XML",
          "DataTech.Batch.ProductInvoice.Pdf": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SEFAZ - DANFE",
          "DataTech.Batch.ProductInvoice.Serpro": "Consulta de Notas Fiscais de Produto (NFE-e) em Lote via SEFAZ e SERPRO como Backup",
          "DataTech.Batch.ProductInvoice.Serpro.Pdf": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SERPRO - DANFE",
          "DataTech.Batch.ProductInvoice.Serpro.Xml": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SERPRO - XML",
          "DataTech.Batch.ProductInvoice.Xml": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SEFAZ - XML",
          "DataTech.LegalEntity": "Consulta de CNPJ",
          "DataTech.NaturalPerson": "Consulta de CPF",
          "DataTech.ProductInvoice": "Consulta de Notas Fiscais de Produto (NF-e)",
          "DataTech.WebHook": "Webhooks para Consulta de dados",
          "status": {
            "Active": "Ativo",
            "Inactive": "Inativo"
          }
        },
        "tableHeader": {
          "providerName": "Funcionalidade"
        },
        "text": "Nesse painel você verá todas as funcionalidades disponíveis em nossa plataforma e escolher quais gostaria de utilizar.",
        "toggleProvider": {
          "error": "Erro ao {action, select, activate {ativar} deactivate {desativar}} funcionalidade.",
          "success": "Funcionalidade {action, select, activate {ativada} deactivate {desativada}} com sucesso!"
        }
      },
      "title": "Gerenciamento da conta",
      "usage": {
        "actions": {
          "Company": {
            "All": "Listar as empresas ativas de uma conta",
            "Create": "Criar uma empresa",
            "Delete": "Excluir uma empresa",
            "One": "Obter os detalhes de uma empresa",
            "Save": "Atualizar uma empresa",
            "UploadCertificate": "Upload do certificado digital da empresa usando o codificação multipart/form-data"
          },
          "ConsumerInvoice": {
            "CancelInvoice": "Enviar para cancelamento",
            "CheckAuthorizationBatch": "Validar protocolo do lote de autorização",
            "DequeueIssue": "Desenfileirar para emissão",
            "EnqueueCancel": "Inclusão na fila de cancelamento",
            "EnqueueIssue": "Enfileirar para emissão",
            "GenerateDanfe": "Geração do DANFE",
            "GenerateXml": "Geração de XML",
            "GetEvents": "Consulta de eventos",
            "GetInvoice": "Consulta de cupom fiscal eletrônico por chave de acesso",
            "GetInvoicePdf": "Consulta de cupom fiscal eletrônico por chave de acesso com geração de PDF",
            "GetInvoiceXml": "Consulta de cupom fiscal eletrônico por chave de acesso com geração de XML",
            "GetOne": "Consulta por identificador",
            "MergeAuthorizationProtocol": "Adicionar protocolo no XML de envio",
            "MergeCancelProtocol": "Adicionar protocolo no XML de cancelamento",
            "Push": "Disparo de WebHooks",
            "SeekNextSerialNumber": "Encontrar próximo número da série",
            "SendAuthorizationBatch": "Enviar lote de autorização"
          },
          "LegalEntity": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Receita Federal > Consulta de dados básicos",
            "GetBasicInfoV2": "Receita Federal > Consulta de dados básicos V2",
            "GetStateTaxInfo": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetStateTaxInfoWebService": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetTaxRegime": "Receita Federal > Consulta do Simples Nacional",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração"
          },
          "LegalEntityV2": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Consulta de dados do CNPJ",
            "GetBasicInfoV2": "Consulta de dados do CNPJ V2",
            "GetStateTaxInfo": "Consulta de Inscrição Estadual por CNPJ",
            "GetStateTaxInfoWebService": "Consulta de Inscrição Estadual por CNPJ e Estado",
            "GetTaxRegime": "Consulta de Simples Nacional por CNPJ",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração"
          },
          "NaturalPerson": {
            "All": "Pessoas Físicas > Consulta de todas",
            "Create": "Pessoa Física > Criação",
            "GetAddress": "Consulta de Situação Cadastral do CPF com endereço",
            "GetComplete": "Consulta de Situação Cadastral do CPF completo",
            "GetStatus": "Consulta de Situação Cadastral do CPF",
            "One": "Pessoa Física > Consulta por identificador",
            "Save": "Pessoa Física > Alteração"
          },
          "OneWebhook": "Conta > Consulta de um WebHook",
          "ProductInvoice": {
            "CancelInvoice": "Enviar para cancelamento",
            "CheckAuthorizationBatch": "Validar protocolo do lote de autorização",
            "CorrectionLetterInvoice": "Geração de carta de correção",
            "DequeueIssue": "Desenfileirar para emissão",
            "GenerateCorrectionLetterDanfe": "Geração de DANFE de carta de correção",
            "GenerateCorrectionLetterXml": "Geração de XML de carta de correção",
            "GenerateDanfe": "Geração do DANFE",
            "GenerateRejectionXml": "Geração de XML de nota rejeitada",
            "GenerateXml": "Geração de XML",
            "GetEvents": "Consulta de eventos",
            "GetInvoice": "Consulta de Nota Fiscal Eletrônica na SEFAZ por chave de acesso",
            "GetInvoicePdf": "Consulta de Nota Fiscal Eletrônica na SEFAZ por chave de acesso com geração de PDF",
            "GetInvoiceXml": "Consulta de Nota Fiscal Eletrônica na SEFAZ por chave de acesso com geração de XML",
            "GetItems": "Consulta itens",
            "GetOne": "Consulta por identificador",
            "GetSerproInvoice": "Consulta de Nota Fiscal Eletrônica na SERPRO por chave de acesso",
            "GetSerproInvoicePdf": "Consulta de Nota Fiscal Eletrônica na SERPRO por chave de acesso com geração de PDF",
            "GetSerproInvoiceXml": "Consulta de Nota Fiscal Eletrônica na SERPRO por chave de acesso com geração de XML",
            "MergeAuthorizationProtocol": "Adicionar protocolo no XML de envio",
            "MergeCancelProtocol": "Adicionar protocolo no XML de cancelamento",
            "MergeCorrectionLetterProtocol": "Adicionar protocolo no XML de carta de correção",
            "SeekNextSerialNumber": "Encontrar próximo número da série",
            "SendAuthorizationBatch": "Enviar lote de autorização",
            "SendWebhook": "Webhook enviado"
          },
          "ProductInvoicesV2": "Nota Fiscal Eletrônica (v2)",
          "ServiceInvoice": {
            "AllWebhooks": "Consulta de WebHooks",
            "CalculateTaxes": "Nota em etapa de calculo de impostos",
            "Cancel": "Nota enviada para cancelamento",
            "Cancelled": "Nota cancelada",
            "CheckBatch": "Nota aguardando retorno da prefeitura",
            "Create": "Lote criado",
            "Download": "Download do PDF da nota",
            "GetOne": "Consulta por identificador",
            "Issued": "Nota emitida",
            "ListAll": "Consulta de todas",
            "PushToWebhooks": "Webhook enviado",
            "QueueCancel": "Nota na fila de cancelamento",
            "QueueIssue": "Nota na fila de emissão",
            "QueueRetry": "Nota reenviada para processamento",
            "Save": "Lote atualizado",
            "SendBorrowerEmail": "Email com a nota fiscal enviado",
            "Sent": "Nota enviada para prefeitura",
            "Update": "Atualização de dados"
          },
          "StateTaxV2": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Consulta de dados do CNPJ",
            "GetBasicInfoV2": "Consulta de dados do CNPJ V2",
            "GetStateTaxForInvoice": "Consulta de Inscrição Estadual por CNPJ para avalição de Emissão de Nota Fiscal de Produto",
            "GetStateTaxInfo": "Consulta de Inscrição Estadual por CNPJ",
            "GetStateTaxInfoWebService": "Consulta de Inscrição Estadual por CNPJ e Estado",
            "GetStateTaxSuggestedForInvoice": "Consulta de Inscrição Estadual por CNPJ para avalição de Emissão de Nota Fiscal de Produto Caso existir mais de uma HABILITADA, será retornado a melhor inscrição estadual para emitir nota em critério de avaliação da NFE.io",
            "GetTaxRegime": "Consulta de Simples Nacional por CNPJ",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração",
            "TaxPayerRegistrationGetOneAbledStateTaxInfoAsync": "Sintegra > Inscrição Estadual com filtro de habilitadas"
          },
          "UpdateWebhook": "Conta > Atualização de WebHook"
        },
        "descriptions": {
          "ConsumerInvoice": "Processamento de Nota Fiscal De Consumidor (NFC-e)",
          "DataTech.ProductInvoice": "Consulta de Nota Fiscal Eletrônica (NF-e)",
          "LegalEntity": "Consulta de Pessoa Jurídica (CNPJ)",
          "LegalEntityV2": "Consulta de Pessoa Jurídica (CNPJ) V2",
          "NaturalPerson": "Consulta de Pessoa Física (CPF)",
          "ProductInvoice": "Processamento de Nota Fiscal Eletrônica (NF-e)",
          "ServiceInvoice": "Processamento de Nota Fiscal de Serviço (NFS-e)",
          "StateTaxV2": "Consulta de Inscrição Estadual V2"
        },
        "error": "Erro",
        "filter": "Filtro Mensal",
        "footer": "Detalhes do uso da conta",
        "header": "Resumo de utilização",
        "noUsage": "Não houve utilização em",
        "notFound": "Não encontrado",
        "resource": "Recurso",
        "resourceName": {
          "Company": "Empresas",
          "ConsumerInvoice": "Nota Fiscal de Consumidor",
          "LegalEntity": "Pessoa Jurídica",
          "LegalEntityV2": "Pessoa Jurídica",
          "NaturalPerson": "Pessoa Física",
          "ProductInvoice": "Nota Fiscal Eletrônica",
          "ServiceInvoice": "Nota Fiscal de Serviço",
          "StateTaxV2": "Inscrição Estadual"
        },
        "subTitle": "{accountName}, atualizado {lastUpdate}",
        "success": "Sucesso",
        "text": "Nesse painel você terá acesso ao resumo do consumo de sua conta com base no registro que fazemos de todas as chamadas realizadas para nossas APIs.<br><br><strong>Importante o resumo é atualizado uma vez ao dia.</strong>",
        "total": "Total"
      },
      "users": {
        "createBtn": "Adicionar Usuário",
        "delete": {
          "error": "Falha ao excluir o usuário",
          "success": "Usuário exluído com sucesso"
        },
        "dialog": {
          "content": "Esta ação irá excluir o usuário <strong>{userEmail}</strong>",
          "title": "Você tem certeza que deseja excluir este usuário?"
        },
        "header": "Usuários",
        "new": {
          "steps": {
            "confirmation": {
              "error": "Falha ao enviar o convite",
              "label": "Confirmação de envio",
              "sending": "Enviando convite...",
              "success": "Convite enviado com sucesso!",
              "userIsNotAdmin": "Somente o administrador da conta pode adicionar usuários",
              "userWithoutAccount": "Usuário não tem vínculo com uma conta"
            },
            "defineUser": {
              "addUserBtn": "Enviar convite",
              "label": "Email do novo usuário"
            }
          },
          "title": "Adicionar usuário"
        },
        "tableBody": {
          "buttons": {
            "deleteUser": "Excluir"
          },
          "emptyList": {
            "text": "Somente o administrador da conta pode criar e excluir usuários, entre em contato com o administrador da sua conta e solicite a alteração que você deseja.",
            "title": "Recurso exclusivo para o Administrador da conta"
          },
          "tooltips": {
            "deleteUser": "Excluir usuário"
          }
        },
        "tableHeader": {
          "avatar": "Avatar"
        },
        "text": "Acesse esse painel para ver todos os usuários que tem acesso a sua conta. Aqui você poderá adicionar e excluir usuários."
      },
      "webhooks": {
        "createBtn": "Criar Webhook",
        "emptyList": {
          "text": "Os <strong>Webhooks (Gatilhos)</strong> são responsáveis por monitorar e notificar sobre os eventos gerados por nossa plataforma, por exemplo quando uma nota fiscal foi emitida ou cancelada.",
          "title": "Nenhum webhook encontrado"
        },
        "header": "Webhooks",
        "knowMore": "Saiba mais sobre o funcionamento dos webhooks.",
        "search": "Digite para Pesquisar...",
        "text": "Um webhook é uma funcionalidade que permite receber notificações de determinados eventos que ocorrem no NFe.io diretamente para sua aplicação Web. Para isso basta criar um webhook para uma URL da sua aplicação que receberá uma requisição POST com os dados do evento no formato em JSON.",
        "text2": "Os <strong>Webhooks (Gatilhos)</strong> são responsáveis por monitorar e notificar sobre os eventos gerados por nossa plataforma, por exemplo quando uma nota fiscal foi emitida ou cancelada.",
        "title": "Nenhum webhook cadastrado! Mas o que são webhooks?",
        "tooltip": "Alterar Webhook"
      }
    },
    "accounts": {
      "activateAction": "Ativar Conta",
      "changeAccount": "Trocar de Conta",
      "deactivateAction": "Desativar Conta",
      "emptyList": {
        "text": "A <b>Conta</b> é a base para controle da plataforma de modo geral, nesse registro chave que temos tudo relacionado como <b>Empresas</b>, <b>Chaves de Acesso</b>, <b>Webhooks (Gatilhos)</b>, <b>Consumo</b>, <b>Cobranças</b> e etc.",
        "title": "Nada encontrado na pesquisa"
      },
      "header": "Contas",
      "search": "Pesquisar",
      "setEnvironmentDevelopmentAction": "Alterar para Desenvolvimento",
      "setEnvironmentProductionAction": "Alterar para Produção",
      "textLinkToAccounts": "Ir para conta e contrato",
      "title": "Gerenciamento de contas",
      "updateUsage": "Atualizar Uso",
      "usage": "Ver uso da plataforma",
      "useAs": "Usar como essa conta"
    },
    "action": "Ação",
    "actionsBtn": "Ações",
    "activate": "Ativar",
    "activateNow": "Ativar Agora",
    "active": "Ativo",
    "additionalInformation": "Complemento",
    "address": "Endereço",
    "agency": "Agência",
    "amount": "Valor",
    "apisReference": "Referência de APIs",
    "approve": "Aprovar",
    "bank": "Banco",
    "begin": "Início",
    "cancelBtn": "Cancelar",
    "certificateStatuses": {
      "Active": "Ativo",
      "Expired": "Vencido",
      "Expiring": "Vencendo",
      "Pending": "Aguardando upload"
    },
    "cityState": "Cidade/UF",
    "closeBtn": "Fechar",
    "companies": {
      "actions": {
        "editLong": "Alterar Empresa",
        "editTooltip": "Alterar Empresa",
        "issueBatchInvoices": "Emitir",
        "issueBatchInvoicesLong": "Emitir Notas Fiscais em Lote",
        "issueBatchInvoicesTooltip": "Emitir Notas Fiscais em Lote",
        "listInvoices": "Listar",
        "listInvoicesLong": "Listar Notas Fiscais",
        "listInvoicesTooltip": "Listar Notas Fiscais",
        "testIssue": "Testar emissão"
      },
      "batch": {
        "all": "Todas",
        "cancelled": "Canceladas",
        "error": "Erro",
        "exitIssueBatchModal": {
          "cancelBtn": "Cancelar emissão",
          "text": "Suas notas ainda estão sendo emitidas. Se você sair, o processo de emissão será encerrado.",
          "title": "Deseja mesmo sair da emissão em lote?"
        },
        "header": "Emitir Notas Fiscais em lote",
        "issued": "Emitidas",
        "processing": "Processando",
        "sendBtn": "Emitir em lote",
        "sent": "Enviadas",
        "status": {
          "fail": "Enviado com falha",
          "issued": "Emitida com sucesso",
          "sending": "Enviando...",
          "success": "Enviado com sucesso",
          "uploadFail": "Upload com falha",
          "waiting": "Aguardando"
        },
        "steps": {
          "howToFill": {
            "text": "Acesse a <a href={link} target='_blank'>documentaçao</a> para mais detalhes sobre os campos.",
            "title": "Como preencher sua planilha"
          },
          "step1": {
            "description": "Nesse passo a passo iremos te ensinar a emitir notas fiscais em lote através de uma planilha de excel, e todo o conhecimento técnico para você conseguir preencher os campos da sua planilha.",
            "title": "Deseja aprender a emitir notas ficais em lote?"
          },
          "step2": {
            "advancedSheet": {
              "description": "Os impostos serão calculados de acordo com as alíquotas <strong> informadas pelo usuário nos campos da planilha.</strong>",
              "title": "Planilha avançada"
            },
            "basicSheet": {
              "description": "Os impostos serão calculados conforme alíquotas disponíveis no nosso sistema de <strong>cálculo automático de impostos.</strong>",
              "title": "Planilha básica"
            },
            "description": "Disponibilizamos dois modelos de planilhas para que você possa usá-las para emitir suas notas fiscais.",
            "title": "Escolher modelo da planilha"
          },
          "step3": {
            "description": "Nos botões abaixo você poderá baixar sua planilha e entender como preenchê-la adequadamente.",
            "download": "Baixar planilha",
            "title": "Baixar e preencher planilha"
          },
          "step4": {
            "inputLabel": "Arquivo da planilha excel com extensão XLSX",
            "title": "Selecionar planilha e validar"
          },
          "step5": {
            "goToIssue": "Ir para emissão",
            "status": "Importando planilha",
            "title": "Importar planilha"
          }
        },
        "table": {
          "additionalInformation": "Informações adicionais",
          "address": "Endereço",
          "borrowerType": "Tipo Tomador",
          "cnaeCode": "CNAE",
          "cofinsAmountWithheld": "Retenção COFINS (R$)",
          "country": "País",
          "csllAmountWithheld": "Retenção CSLL (R$)",
          "deductionsAmount": "Valor Deduções (R$)",
          "description": "Descrição",
          "discountConditionedAmount": "Valor Desconto Condicionado (R$)",
          "discountUnconditionedAmount": "Valor Desconto Incondicionado (R$)",
          "federalTaxNumber": "Tomador CPF/CNPJ",
          "inssAmountWithheld": "Retenção INSS (R$)",
          "invalidAddress": "Endereço inválido",
          "irAmountWithheld": "Retenção IR (R$)",
          "issAmountWithheld": "Retenção ISS (R$)",
          "issRate": "Alíquota ISS",
          "issTaxAmount": "Valor ISS (R$)",
          "issuedOn": "Data de Competência",
          "location": "Local da Prestação",
          "modal1": "Por favor corrija os campos listados abaixo",
          "modal2": "Erro ao enviar Nota Fiscal",
          "municipalTaxNumber": "Inscrição Municipal",
          "name": "Tomador Nome/Razão Social",
          "othersAmountWithheld": "Retenção OUTROS (R$)",
          "paidAmount": "Valor Recebido (R$)",
          "pisAmountWithheld": "Retenção PIS (R$)",
          "postalCode": "CEP",
          "serviceCode": "Cod. Serviço",
          "showError": "Clique para ver mais detalhes",
          "state": "UF / Cidade",
          "status": "Status",
          "taxationType": "Tipo da tributação",
          "value": "Valor (R$)"
        },
        "total": "Total",
        "uploadBtn": "Fazer novo upload"
      },
      "doubt": "Ainda tem dúvidas? Clique e saiba mais!",
      "edit": {
        "alert": "Atenção!",
        "apikeys": {
          "accessKeyDetails": {
            "companyId": "Empresa ID",
            "text": "O <strong>API key</strong> e o <strong>Empresa ID</strong> são responsáveis pela autenticação na API do NFe.io e por associar as transações realizadas à empresa. Mantenha-a em sigilo para segurança de seus dados. Acesse a <a href='http://nfe.io/docs' target='_blank'>documentação</a> para mais detalhes.",
            "title": "Dados para acesso da API"
          },
          "title": "Chaves de Acesso"
        },
        "basicInfo": {
          "boxFooter": "Detalhes da empresa",
          "subtitle": "Mostrando os dados básicos da empresa",
          "title": "Informações da empresa"
        },
        "certificate": {
          "description": "O Certificado Digital é uma assinatura com validade jurídica que garante proteção às transações eletrônicas e outros serviços via internet, permitindo que pessoas e empresas se identifiquem e assinem digitalmente de qualquer lugar do mundo com mais segurança e agilidade.",
          "errors": {
            "default": "Erro ao enviar o certificado",
            "invalidCnpj": "CNPJ do certificado não corresponde ao CNPJ da empresa",
            "invalidPassword": "Senha do certificado inválida",
            "invalidType": "Tipo do certificado diferente de e-CNPJ ou e-NFE",
            "overdue": "O certificado está vencido"
          },
          "expiresOn": "Válido até",
          "modifiedOn": "Atualizado há",
          "stepper": {
            "chooseFile": {
              "label": "Escolher arquivo do Certificado Digital"
            },
            "exportFile": {
              "description": "Clique no sistema operacional que você utiliza para ver o video sobre como exportar o seu certificado digital",
              "label": "Exportar o arquivo do Certificado Digital",
              "modal": {
                "certificateExportDoc": "Para ter acesso ao passo a passo em formato de texto, <a href=\"{docLink}\" target=\"blank\">Clique aqui!</a>",
                "macOs": {
                  "name": "Mac OS",
                  "title": "Como exportar o seu certificado digital no Mac OS"
                },
                "windows": {
                  "name": "Windows",
                  "title": "Como exportar o seu certificado digital no Windows"
                }
              }
            },
            "hasCertificate": {
              "description": "Você precisa ter o arquivo do certificado digital em seu computador, esse arquivo sempre tem a extensão .PFX ou .P12.",
              "label": "Você está com o arquivo do Certificado Digital?"
            },
            "password": {
              "label": "Senha do Certificado Digital"
            },
            "sendFile": {
              "label": "Enviar o arquivo",
              "messages": {
                "sending": "Enviando o arquivo para o sistema da NFE.io"
              }
            }
          },
          "subtitle": "Mostrando o Certificado Digital",
          "text": "Utilize o formulário abaixo para fazer o upload do certificado digital",
          "title": "Certificado Digital",
          "update": {
            "alreadyBoughtBtn": "Sim já comprei",
            "didntBoughtBtn": "Ainda não comprei",
            "title": "Você já comprou o certificado digital do tipo e-CNPJ A1 ou NF-e A1?",
            "usageTip": "O certificado digital será utilizado na autenticação com os sistemas do Governo e Prefeitura, é como um usuário e senha que você usa em um site. <a href=\"https://nfe.io/docs/certificado-digital/conceitos/\" target=\"blank\">Saiba mais!</a>",
            "usageTip2": "Também iremos utilizar o seu certificado na assinatura digital que é enviada nas solicitações que nossa plataforma faz com os sistemas do Governo e Prefeitura, por exemplo para emitir e cancelar notas fiscais."
          },
          "updatedWithSuccess": "Certificado enviado com sucesso!"
        },
        "certificateDateInfo": "Certificado digital com vencimento",
        "certificateExpiredInfo": "Certificado digital vencido",
        "certificateExpiringInfo": "Certificado digital vencendo",
        "certificateExpiringTip": "Atualize seu certificado digital o quanto antes e evite problemas",
        "certificateTitleWithModifiedOnAndExpiresOn": "Certificado Digital foi atualizado {modifiedOnFromNow} com vencimento em {expiresOn}",
        "certificateUploadInfo": "Aguardando certificado digital",
        "certificateUploadTip": "Você precisa do certificado digital para emitir as notas fiscais em produção",
        "environment": {
          "cardText": "Aqui você poderá alterar o ambiente onde sua empresa está operando. Utilize nosso ambiente de <strong>testes</strong> para simular a emissão de suas notas sem valor fiscal, ou o ambiente de <strong>produção</strong> para emitir através dos sistemas do Governo e Prefeituras.",
          "cardTitle": "Empresa operando no {environmentTitle}",
          "developmentEnv": {
            "description": "No <strong>ambiente de testes</strong> todas as notas fiscais serão processadas por servidores e sistemas que imitam o comportamento do Governo e Prefeituras.",
            "text": "Nesse ambiente as notas fiscais <u>NÃO</u> são processadas pelo sistema do Governo e Prefeitura, ou seja, <strong>você não irá pagar impostos pelas notas fiscais</strong>.",
            "title": "Ambiente de Testes"
          },
          "error": {
            "default": "Ocorreu um erro ao alterar o ambiente!",
            "needActivate": "Sua conta precisa ser ativada, fale com a equipe comercial@nfe.io",
            "notReady": "Não foi possivel alterar o ambiente. Verifique os dados da empresa e o certificado digital."
          },
          "modal": {
            "text": {
              "certificateExpired": "O certificado digital da sua empresa está vencido.",
              "changeEnvironment": "Esta operação irá alterar o ambiente de processamento das notas fiscais eletrônicas.",
              "confirm": "Você tem certeza que deseja continuar?",
              "uploadCertificate": "Para alterar o ambiente para produção, você precisa primeiro fazer o upload do certificado digital da sua empresa."
            },
            "title": {
              "confirm": "Alterar ambiente para {environmentTitle}?",
              "waitingCertificate": "Aguardando Certificado Digital"
            },
            "uploadBtn": "Fazer upload do certificado digital"
          },
          "productionEnv": {
            "description": "No <strong>ambiente de produção</strong> todas as notas fiscais serão processadas pelos servidores e sistemas do Governo e Prefeituras.",
            "text": "Nesse ambiente as notas fiscais serão processadas pelo sistema do Governo e Prefeitura, ou seja, <strong>você irá pagar impostos pelas notas fiscais</strong>.",
            "title": "Ambiente de Produção"
          },
          "subtitle": "Mostrando as informações do Ambiente",
          "success": "Ambiente alterado com sucesso!",
          "switchEnvironmentButton": "Alterar para o {environmentTitle}",
          "title": "Ambiente"
        },
        "environmentDescriptions": {
          "Development": "Sua empresa está operando em modo de teste. <strong>Todas as notas fiscais emitidas não terão valor fiscal.</strong>",
          "Production": "Sua empresa está operando no modo de produção, todas as notas fiscais serão emitidas na prefeitura."
        },
        "fiscalInfo": {
          "issInfo": {
            "text": "Está alíquota será utilizada por nossa plataforma para cálculo do ISS, caso o campo <b>Regime Tributário</b> esteja marcado como <b>Simples Nacional</b> ou <b>Microempreendedor Individual (MEI)</b>.",
            "title": "Alíquota do Imposto Sob Servico (ISS)"
          },
          "message": {
            "error": "Erro ao alterar empresa.",
            "success": "Empresa alterada com sucesso!"
          },
          "modal": {
            "changeCertificateWarning": "<p>Esta operação irá alterar os dados fiscais para esta empresa. Segue abaixo os campos que serão alterados caso você confirme a mudança:</p><ul style='list-style:inside'><li>Alíquota do ISS: {issAliquot};</li><li>Série do RPS: {rpsSerie}</li><li>Número do RPS: {rpsNumber}</li></ul><p>Deseja continuar? Esta mudança irá afetar diretamente o processamento de notas fiscais!</p>",
            "title": "Confirmar alteração de dados fiscais?"
          },
          "rpsInfo": {
            "text": "RPS é documento fiscal que é utilizado temporariamente até que a NFS-e seja emitida. Como o RPS é um documento fiscal, nossa plataforma tem o dever de controlar o Número do RPS, para que a NFS-e sejam emitida corretamente. <br><b>Série do RPS</b>: é um texto utilizado para agrupar sequencia de números do RPS.<br><b>Número do RPS</b>: é um número inteiro sequencial que identifica o RPS.",
            "title": "Recibo Provisório de Serviços (RPS)"
          },
          "subtitle": "Mostrando os Dados Fiscais",
          "title": "Dados Fiscais"
        },
        "taxes": {
          "card": {
            "title": "Lista de serviços cadastrados"
          },
          "description": "Pesquise pelo código de serviço que deseja utilizar em suas emissões e veja se está disponível em nosso sistema de cálculo automático de imposto.",
          "emptyList": {
            "text": "Por favor, clique no botão abaixo para solicitar o cadastro do seu código de serviço em nossa plataforma. Daremos prioridade máxima para sua solicitação.",
            "title": "Ops! Esse código de serviço não está cadastrado em nossa plataforma, ainda!"
          },
          "list": {
            "header": {
              "cityServiceCode": "Cód. de Serviço",
              "federalCode": "Cód. Federal (LC-116)",
              "issAliquota": "Alíquota do ISS"
            },
            "newServiceDialog": {
              "getFile": {
                "header": "Obter arquivo de nota fiscal para referência",
                "issuersDescription": "<b>Caso você já emita nota:</b> utilize um arquivo (pdf ou xml) de uma de suas notas fiscais emitida recentemente.",
                "notIssuersDescription": "<b>Caso você ainda não emita nota:</b> valide com seu contador se é possível emitir uma nota no site da prefeitura com valor de 1 centavo, apenas para obter o arquivo (pdf ou xml) da nota fiscal.</li>"
              },
              "header": "Solicitação do código de serviço",
              "newBtn": "Solicitar Cadastro",
              "sendFile": {
                "header": "Envio dos dados para cadastro do serviço",
                "receiveResponse": "Agora é só aguardar, nossa contadora irá cadastrar os dados dos seu serviço na nossa plataforma de impostos e te retornamos dentro de 24h.",
                "sendEmailWithInvoice": "Envie um email para <a href=\"mailto:suporte@nfe.io?subject=Cadastro%20de%20código%20de%20serviço\">suporte@nfe.io</a> colocando \"Cadastro de código de serviço\" no assunto, lembre-se de anexar o arquivo (pdf ou xml) da nota fiscal para referência."
              }
            },
            "rates": {
              "cofins": "COFINS",
              "csll": "CSLL",
              "inss": "INSS",
              "ir": "IR",
              "iss": "ISS",
              "pis": "PIS"
            },
            "taxDialog": {
              "cnae": "CNAE Nacional",
              "title": "Detalhes do Serviço",
              "withHoldingTitle": "Alíquotas dos impostos retidos na fonte"
            }
          },
          "searchTax": "Pesquisar código de serviço",
          "title": "Lista de serviços cadastrados em {city} - {state}"
        },
        "title": "Gerenciamento da empresa",
        "updateCertificateOk": "Certificado Digital foi atualizado"
      },
      "emptyList": {
        "onFirstTime": {
          "text": "Tudo começa pela <b>Empresa</b>, para o <b>processamento de notas fiscais</b>. As empresas são uma parte essencial da plataforma pois no momento da emissão da nota fiscal você precisa informar quem é a empresa que prestou o serviço ou que vendeu o produto.",
          "title": "Nenhuma empresa cadastrada"
        },
        "onNotFound": {
          "text": "Tudo começa pela <b>Empresa</b>, para o <b>processamento de notas fiscais</b>. As empresas são uma parte essencial da plataforma pois no momento da emissão da nota fiscal você precisa informar quem é a empresa que prestou o serviço ou que vendeu o produto.",
          "title": "Nada encontrado na pesquisa"
        }
      },
      "header": "Empresas",
      "infoForNewCompany": "Para começar você precisará de uma empresa",
      "invoices": {
        "filter": {
          "applyBtn": "Aplicar",
          "created": "Data de criação",
          "curMonth": "Criado nesse mês",
          "curWeek": "Criado nessa semana",
          "curYear": "Criado nesse ano",
          "date": "Data de criação",
          "email": "Email do Tomador",
          "federalTaxNumber": "CNPJ/CPF do Tomador",
          "invoiceId": "ID da NFe",
          "invoiceNumber": "Número da NFe",
          "issued": "Data de emissão",
          "lastMonth": "Criado no último mês",
          "name": "Nome do Tomador",
          "options": "Escolha uma das opções",
          "placeholder": "Equivalente a",
          "status": "Status da Nota Fiscal"
        }
      },
      "linkForNewCompany": "Criar uma empresa agora!",
      "menu": {
        "details": "Alterar empresa",
        "issue": "Emitir",
        "listInvoices": "Listar Notas Fiscais"
      },
      "modal": {
        "description": "<p>Emita notas fiscais em lote fazendo o upload de uma planilha com os dados das notas.</p><h5><b>Não sabe como começar?</b></h5><ul><li>Clique no botão 'Fazer Upload'.</li><li>Siga o passo a passo para fazer as emissões.</li></ul>",
        "header": "Emitir Notas Fiscais em lote",
        "uploadBtn": "Fazer Upload"
      },
      "new": {
        "basicDataLabel": "Dados básicos da empresa",
        "certificate": {
          "text": "Para emitir suas notas fiscais com sucesso precisamos do certificado digital vinculado à sua empresa. Caso queira apenas testas o nosso serviço, você pode pular esta etapa.",
          "title": "Upload do certificado digital"
        },
        "digitalCertificateLabel": "Certificado digital (<strong>Opcional</strong>)",
        "fiscalDataLabel": "Dados fiscais da empresa (<strong>Opcional</strong>)",
        "lastStep": {
          "text": "<p>Clicando no botão 'Criar Empresa', você terá uma empresa pronta para emitir notas fiscais de serviço em um ambiente de simulação que é seguro para seus testes.</p><p>Essa empresa será criada em um simulador de prefeitura, para que você possa testar nossa plataforma.</p><p><strong>Recomendamos que seu próximo passo seja emitir sua primeira nota fiscal de teste, na próxima tela vamos te dar algumas opções.</strong></p>",
          "title": "Você está a um passo de criar sua empresa"
        },
        "serviceCode": {
          "description": "Pesquise abaixo o código de serviço prestado pela sua empresa.",
          "title": "Código de Serviço"
        },
        "stateTaxDataLabel": "Inscrição Estadual",
        "success": "Empresa Criada com sucesso!",
        "title": "Criar empresa",
        "waysToStart": "Sua empresa ainda não emitiu nenhuma nota fiscal, por isso sugerimos que você comece aprendendo <a href='https://nfe.io/docs/nota-fiscal-servico/conceitos-nfs-e/' target='_blank'>tudo sobre Nota Fiscal de Serviço</a>, abaixo temos algumas opções de como você pode emitir notas fiscais com a nossa plataforma."
      },
      "productInvoices": {
        "events": {
          "modal": {
            "accessKeyCheckDigit": "Dígito verificador",
            "batchId": "ID do lote",
            "protocolNumber": "Número de protocolo",
            "receiptNumber": "Número de recibo",
            "title": "Detalhes do evento"
          },
          "title": "Eventos da Nota Fiscal"
        },
        "header": {
          "Production": "Notas Fiscais do ambiente de produção",
          "Test": "Notas Fiscais do ambiente de teste"
        },
        "modal": {
          "cancelInvoiceMessage": "<p>Está operação irá cancelar a NF-e <i>#{invoiceNumber}</i>,</p><p>emitida em <i>{issuedOn}</i>.</p><p><strong>Você tem certeza que deseja continuar?</strong></p>",
          "errorTitle": "Mensagem de Erro da NF-e"
        },
        "status": {
          "cancelled": "Canceladas",
          "created": "Criada",
          "disabled": "Inutilizada",
          "error": "Erro",
          "issueDenied": "Uso denegado",
          "issued": "Emitidas",
          "none": "Todas",
          "processing": "Processando"
        },
        "view": {
          "buyer": "Destinatário",
          "cityState": "Cidade/UF",
          "eventsDetails": "Mais detalhes dos eventos",
          "freightModality": "Modalidade do frete",
          "generalData": "Dados Gerais",
          "icms": {
            "baseTax": "Base de Cálculo ICMS",
            "icmsAmount": "Valor Total ICMS",
            "icmsExemptAmount": "Valor ICMS Desonerado",
            "stCalculationBasisAmount": "Base de Cálculo ICMS ST"
          },
          "issuance": "Emissão",
          "issueDate": "Data de emissão",
          "issuer": "Emitente",
          "lastEvents": "Últimos Eventos",
          "operationNature": "Natureza da operação",
          "operationType": "Tipo da operação",
          "purpose": "Finalidade",
          "serieNumber": "Série/Número",
          "totals": {
            "cofinsAmount": "Valor do COFINS",
            "discountAmount": "Valor Total Desconto",
            "federalTaxesAmount": "Valor Aproximado dos Tributos",
            "freightAmount": "Valor do Frete",
            "header": "Totais",
            "iiAmount": "Valor Total II",
            "insuranceAmount": "Valor Seguro",
            "ipiAmount": "Valor Total IPI",
            "othersAmount": "Outras Despesas acessórias",
            "pisAmount": "Valor do PIS",
            "productAmount": "Valor Total dos Produtos",
            "total": "Valor Total da NFe"
          }
        }
      },
      "serviceInvoices": {
        "actions": {
          "reissue": "Enviar para re-emissão",
          "sendEmail": "Enviar e-mail",
          "showError": "Ver erro",
          "showInvoice": "Visualizar"
        },
        "emptyList": {
          "onFirstTime": {
            "text": "A <b>Nota Fiscal</b> é um documento eletrônico, para fins fiscais, que documenta as operações de circulação de mercadorias e prestação de serviço entre as parte, seja ela <b>Empresas</b> ou <b>Pessoas Físicas</b>.<br>Ainda está com dúvidas, <a target=_blank href='https://nfe.io/docs/conceitos/notas-fiscais/'>saiba mais</a> e entenda tudo sobre Nota Fiscal Eletrônica.",
            "title": "Nenhuma nota fiscal foi emitida"
          },
          "onNotFound": {
            "text": "A <b>Nota Fiscal</b> é um documento eletrônico, para fins fiscais, que documenta as operações de circulação de mercadorias e prestação de serviço entre as parte, seja ela <b>Empresas</b> ou <b>Pessoas Físicas</b>.<br>Ainda está com dúvidas, <a target=_blank href='https://nfe.io/docs/conceitos/notas-fiscais/'>saiba mais</a> e entenda tudo sobre Nota Fiscal Eletrônica.",
            "title": "Nada encontrado na pesquisa"
          }
        },
        "flowStatus": {
          "CancelFailed": "Falha ao cancelar",
          "Cancelled": "Cancelada",
          "IssueFailed": "Falha ao emitir",
          "Issued": "Emitida",
          "WaitingCalculateTaxes": "Calculando impostos",
          "WaitingDefineRpsNumber": "Aguardando",
          "WaitingDownload": "Aguardando",
          "WaitingReturn": "Aguardando",
          "WaitingReturnCancel": "Aguardando cancelamento",
          "WaitingSend": "Aguardando",
          "WaitingSendCancel": "Aguardando"
        },
        "generalStatus": {
          "CancelFailed": "com falha ao cancelar",
          "Cancelled": "Cancelada",
          "DownloadFailed": "com falha no download da nota",
          "IssueFailed": "Falha ao emitir",
          "Issued": "Emitida",
          "WaitingCalculateTaxes": "Calculando impostos",
          "WaitingDefineRpsNumber": "Definindo número de RPS",
          "WaitingDownload": "Baixando nota",
          "WaitingReturn": "Aguardando prefeitura",
          "WaitingReturnCancel": "Aguardando cancelamento",
          "WaitingSend": "Enviando",
          "WaitingSendCancel": "Enviando cancelamento"
        },
        "header": "Notas Fiscais",
        "modal": {
          "cancelInvoiceMessage": "<p>Está operação irá cancelar a NFS-e <i>#{invoiceNumber}</i>,</p><p>emitida em <i>{issuedOn}</i></p><p>para o tomador <i>{borrowerName}</i>.</p><p><strong>Você tem certeza que deseja continuar?</strong></p>",
          "cancelInvoiceTitle": "Confirmar operação de cancelamento",
          "duplicateInvoiceMessage": "<p><strong>Está operação irá emitir uma nova NFS-e baseda na NFS-e abaixo,</strong></p><p>criada para emissão em <i>{issuedOn}</i>,</p><p>para o tomador <i>{borrowerName}</i>,</p><p>com CNPJ/CPF <i>{borrowerFederalTaxNumber}</i>,</p><p>no valor de <i>{servicesAmount}</i>.</p><p><strong>Você tem certeza que deseja continuar?</strong></p><p><strong>Este procedimento irá emitir uma nota fiscal!</strong></p>",
          "duplicateInvoiceTitle": "Confirmar duplicação de NFS-e",
          "errorTitle": "Mensagem de Erro da NFS-e"
        },
        "status": {
          "Cancelled": "Cancelada",
          "Created": "Processando",
          "Error": "Erro",
          "Issued": "Emitida",
          "None": "Todas"
        },
        "tableBody": {
          "cancelledDateDescription": "Cancelado em ",
          "createdDateDescription": "Criado em ",
          "issuedDateDescription": "Emitido em "
        },
        "tableHeader": {
          "amount": "Valor (R$)",
          "borrower": "Tomador do Serviço",
          "cancelledOn": "Cancelado",
          "createdOn": "Criado",
          "date": "Data",
          "description": "Descrição",
          "federalTaxNumber": "Tomador CNPJ/CPF",
          "issuedOn": "Emitido",
          "number": "#NF",
          "rps": "#RPS",
          "serie_number": "Série / #NF",
          "status": "Status"
        },
        "view": {
          "additionalDetails": "Detalhes adicionais",
          "additionalInformation": "Informações adicionais",
          "address": "Endereço",
          "amountNet": "Valor Líquido",
          "baseTaxAmount": "Base de cálculo",
          "batchCheckNumber": "Número Verificação do Lote",
          "batchNumber": "Número do Lote",
          "borrowerData": "Dados do Tomador",
          "cancelledOn": "Cancelada em",
          "checkCode": "Cód. Verificação",
          "cityServiceCode": "Cód. do Serviço Municipal",
          "cnpj": "CNPJ",
          "cpf": "CPF",
          "createdOn": "Criado em",
          "deductionsAmount": "Valor deduções",
          "federalServiceCode": "Lei Complementar 116",
          "flowMessage": "Motivo processamento",
          "flowStatus": "Status processamento",
          "id": "Identificador (ID)",
          "invoiceData": "Dados da Nota Fiscal",
          "issRate": "Alíquota do ISS (%)",
          "issTaxAmount": "Valor do ISS",
          "issuedOn": "Competência",
          "legalNature": "Natureza Jurídica",
          "modifiedOn": "Modificado em",
          "moreDetails": "Mais informações e detalhes técnicos",
          "municipalTaxNumber": "Inscrição Municipal",
          "number": "Número",
          "paidAmount": "Valor recebido",
          "processedOn": "Processado em",
          "providerData": "Dados do Prestador",
          "rpsNumber": "Número do RPS",
          "rpsSerialNumber": "Série do RPS",
          "rpsStatus": "Status do RPS",
          "rpsType": "Tipo do RPS",
          "serviceDescription": "Descrição do serviço",
          "serviceValues": "Valores do serviço",
          "servicesAmount": "Valor do serviço",
          "specialTaxRegime": "Regime Especial Tributação",
          "subtitle": "Mostrando os dados da Nota Fiscal",
          "taxRegime": "Regime Tributário",
          "taxUnderService": "Imposto Sob Serviço (ISS)",
          "taxesWithheld": "Impostos Retidos",
          "title": "Nota Fiscal",
          "withheld": {
            "cofinsAmountWithheld": "Valor do COFINS",
            "csllAmountWithheld": "Valor do CSLL",
            "inssAmountWithheld": "Valor do INSS",
            "irAmountWithheld": "Valor do IRRF",
            "issAmountWithheld": "Valor do ISS",
            "pisAmountWithheld": "Valor do PIS"
          }
        }
      },
      "singular": "Empresa",
      "types": {
        "nfce": "NFC-e",
        "nfe": "NF-e",
        "nfse": "NFS-e"
      },
      "v2": {
        "edit": {
          "cte": {
            "delete": {
              "error": "Erro ao desabilitar consulta de CT-e distribuição",
              "success": "Consulta de CT-e distribuição desabilitada com sucesso!"
            },
            "description": "O CT-e (Conhecimento de Transporte Eletrônico) é um serviço fornecido pela Sefaz com o objetivo de disponibilizar informações confiáveis para os atores envolvidos na operação utilizando o certificado digital do tipo A1.",
            "developmentAccountModal": {
              "text": "Sua conta está em modo de teste. Por isso iremos considerar apenas consultas a partir do dia atual.",
              "title": "Conta em modo de teste"
            },
            "disableCteModal": {
              "text": "Essa ação irá remover os parâmetros e desativar o serviço de consulta de CT-e distribuição. Deseja continuar?",
              "title": "Desativar consulta de CT-e distribuição"
            },
            "requireActivation": {
              "description": "Clique no botão abaixo para informar os parâmetros de consulta e ativar a <strong>consulta de CT-e</strong>",
              "title": "Funcionalidade não ativada pelo usuário!"
            },
            "requireSupport": {
              "description": "Clique no botão abaixo para entrar em contato com o suporte",
              "title": "Não existe uma chave de CT-e cadastrada para a empresa!"
            },
            "submit": {
              "certificateError": "Por favor, verifique se a sua empresa possui certificado digital ativo em nossa plataforma",
              "error": "Erro ao habilitar consuta de CT-e distribuição",
              "success": "Consulta de CT-e distribuição habilitada com sucesso!"
            },
            "title": "CT-e distribuição",
            "update": {
              "error": "Erro ao atualizar parâmetros CT-e distribuição",
              "success": "Parâmetros CT-e distribuição atualizados com sucesso!"
            }
          },
          "environment": {
            "cardText": "Aqui você poderá alterar o ambiente em que a sua inscrição estadual está operando. Utilize nosso ambiente de <strong>testes</strong> para simular a emissão de suas notas sem valor fiscal, ou o ambiente de <strong>produção</strong> para emitir através dos sistemas do Governo.",
            "cardTitle": "Inscrição Estadual operando no {environmentTitle}",
            "developmentEnv": {
              "description": "No <strong>ambiente de testes</strong> todas as notas fiscais serão processadas por servidores e sistemas que imitam o comportamento do Governo.",
              "text": "Nesse ambiente as notas fiscais <u>NÃO</u> são processadas pelo sistema do Governo, ou seja, <strong>você não irá pagar impostos pelas notas fiscais</strong>.",
              "title": "Ambiente de Testes"
            },
            "productionEnv": {
              "description": "No <strong>ambiente de produção</strong> todas as notas fiscais serão processadas pelos servidores e sistemas do Governo.",
              "text": "Nesse ambiente as notas fiscais serão processadas pelo sistema do Governo, ou seja, <strong>você irá pagar impostos pelas notas fiscais</strong>.",
              "title": "Ambiente de Produção"
            }
          },
          "environmentDescriptions": {
            "Production": "Sua inscrição estadual está operando no modo de produção, todas as notas fiscais serão emitidas no sistema do Governo.",
            "Test": "Sua inscrição estadual está operando em modo de teste. <strong>Todas as notas fiscais emitidas não terão valor fiscal.</strong>"
          },
          "nfe": {
            "delete": {
              "error": "Erro ao desabilitar consulta de NF-e distribuição",
              "success": "Consulta de NF-e distribuição desabilitada com sucesso!"
            },
            "description": "NF-e distribuição é um serviço fornecido pela Sefaz com o objetivo de disponibilizar informações confiáveis para destinatários e/ou interessados na NF-e utilizando o certificado digital do tipo A1.",
            "developmentAccountModal": {
              "text": "Sua conta está em modo de teste. Por isso iremos considerar apenas consultas a partir do dia atual.",
              "title": "Conta em modo de teste"
            },
            "disableNfeModal": {
              "text": "Essa ação irá remover os parâmetros e desativar o serviço de consulta de NF-e distribuição. Deseja continuar?",
              "title": "Desativar consulta de NF-e distribuição"
            },
            "manifestAwarenessModal": {
              "text": "Você tem certeza que deseja que o sistema envie de forma automática o evento de ciência da operação para as notas com essa pendência?",
              "title": "Ativar envio automático do evento de ciência da operação"
            },
            "requireActivation": {
              "description": "Clique no botão abaixo para informar os parâmetros de consulta e ativar a <strong>consulta de NF-e</strong>",
              "title": "Funcionalidade não ativada pelo usuário!"
            },
            "submit": {
              "certificateError": "Por favor, verifique se a sua empresa possui certificado digital ativo em nossa plataforma",
              "error": "Erro ao habilitar consuta de NF-e distribuição",
              "success": "Consulta de NF-e distribuição habilitada com sucesso!"
            },
            "title": "NF-e distribuição",
            "update": {
              "error": "Erro ao atualizar parâmetros NF-e distribuição",
              "success": "Parâmetros NF-e distribuição atualizados com sucesso!"
            },
            "warning": "Para utilizar este recurso do sistema no ambiente de produção da SEFAZ, certifique-se de que o processo não esteja sendo executado através de outro sistema, pois nesse caso pode ocorrer rejeição por consumo indevido em ambos os sistemas."
          },
          "stateTax": {
            "actions": {
              "editStateTax": "Alterar"
            },
            "edit": {
              "title": "Alterar Inscrição Estadual"
            },
            "emptyList": {
              "text": "Clique no botão 'Criar Inscrição Estadual' para cadastrar os dados referentes a Inscrição Estadual da sua empresa.",
              "title": "Nenhuma Inscrição Estadual criada"
            },
            "new": "Criar Ins. Estadual",
            "nfeNumberInfo": {
              "text": "<b>Série</b>: é um número utilizado para agrupar Notas Fiscais Eletrônicas, servindo para controle e identificação do grupo de notas.<br><b>Número da NFe</b>: é um número inteiro sequencial que identifica a Nota Fiscal Eletrônica.",
              "title": "Número da Nota Fiscal Eletrônica"
            },
            "nfeSecurityCredentialInfo": {
              "text": "<b>Identificador do Token CSC:</b> é um número que começa em 1 e identifica qual token está sendo utilizado.<br><b>Código de Segurança do Contribuinte (CSC):</b> é um código alfanumérico exclusivo do contribuinte e da Secretaria da Fazenda (SEFAZ) que é utilizado para gerar o código bidimensional, denominado “QR Code” da Nota Fiscal de Consumidor Eletrônica (NFC-e).",
              "title": "Por que precisamos desses campos?"
            },
            "stateTaxDetails": {
              "stateTaxNumber": "Número da Inscrição",
              "text": "Uma <strong>Inscrição Estadual</strong> representa os dados necessários sobre o cadastro Estadual (ICMS) que é preciso para processar Documentos Fiscais na SEFAZ. Utilize este recurso para editar e/ou criar novas Inscrições Estaduais."
            },
            "subTitle": "Informações da Inscrição Estadual",
            "tableBody": {
              "status": {
                "Active": "Ativo",
                "Inactive": "Inativo"
              }
            },
            "tableHeader": {
              "createdOn": "Criado",
              "number": "Número da IE",
              "serie": "Série",
              "state": "Estado",
              "type": "Tipo"
            },
            "title": "Inscrições Estaduais"
          }
        },
        "enums": {
          "certificateStatuses": {
            "active": "Ativo",
            "expired": "Vencido",
            "expiring": "Vencendo",
            "pending": "Aguardando upload"
          }
        },
        "header": "Empresas-v2",
        "new": {
          "dfeDist": {
            "text": "Selecione o serviço que deseja utilizar.",
            "title": "Dados para consulta"
          },
          "dfeDistLabel": "Consulta DF-e distribuição",
          "lastStep": {
            "text": "<p>Clicando no botão 'Criar Empresa', você terá uma empresa pronta para emitir notas fiscais de produto.</p><p><strong>Recomendamos que seu próximo passo seja emitir sua primeira nota fiscal de teste.</strong></p>",
            "title": "Você está a um passo de criar sua empresa"
          },
          "stateTax": {
            "subTitle": "Criar Inscrição Estadual"
          },
          "stateTaxLabel": "Dados da Inscrição Estadual"
        }
      }
    },
    "companyName": "Razão Social",
    "confirmBtn": "Confirmar",
    "contactUs": "Fale conosco",
    "continueBtn": "Continuar",
    "copy": "Copiar",
    "country": "País",
    "createAccountBtn": "Criar conta",
    "createBtn": "Criar",
    "customer": "Cliente",
    "dashboard": {
      "currentMonth": "Mês atual",
      "graphic": {
        "qtdAxis": "Quantidade",
        "valueAxis": "Valor em reais"
      },
      "header": "Painel",
      "lastMonth": "Mês anterior",
      "totalAmount": "Valor das notas fiscais",
      "totalCanceled": "Total Canceladas",
      "totalCreated": "Total Processando",
      "totalFailed": "Total com Falha",
      "totalInvoices": "Quantidade de notas fiscais",
      "totalIssued": "Total Emitidas",
      "totalIssuedPerMonth": "Total de Notas Emitidas por Mês"
    },
    "deactivate": "Desativar",
    "description": "Descrição",
    "district": "Bairro",
    "docs": "Documentação",
    "downloadPdf": "Baixar PDF",
    "downloadXml": "Baixar XML",
    "edit": "Alterar",
    "email": "Endereço de email",
    "end": "Fim",
    "enums": {
      "apiEnvironments": {
        "Development": "Teste",
        "Production": "Produção",
        "Sandbox": "Testes",
        "Test": "Teste"
      },
      "companyStatuses": {
        "Active": "Ativo para Emissão",
        "CityNotSupported": "Não suportada",
        "Pending": "Aguardando dados"
      },
      "productInvoiceStatuses": {
        "Cancelled": "Cancelada",
        "Created": "Criada",
        "Error": "Falha ao emitir",
        "IssueDenied": "Uso denegado",
        "Issued": "Emitida",
        "None": "-",
        "Processing": "Processando"
      },
      "serviceInvoiceStatuses": {
        "cancelled": "Cancelada",
        "created": "Processando",
        "error": "Erro",
        "issued": "Emitida",
        "none": "Todas"
      },
      "ticket": {
        "DFeTech.ConsumerInvoice.Subtypes": {
          "certificate": "Certificado Digital",
          "errorSendInvoiceData": "Erro ao enviar dados da NF-e",
          "issueFailed": "Falha ao Emitir",
          "others": "Outros"
        },
        "DFeTech.ProductInvoice.Subtypes": {
          "certificate": "Certificado Digital",
          "errorSendInvoiceData": "Erro ao enviar dados da NF-e",
          "invoiceDenied": "NF-e Denegada",
          "invoicePdf": "PDF da NF-e",
          "invoiceRefused": "NF-e Recusada pela Sefaz",
          "issueFailed": "Falha ao Emitir",
          "others": "Outros"
        },
        "DFeTech.ServiceInvoice.Subtypes": {
          "borrowerEmail": "E-mail do Tomador",
          "certificate": "Certificado Digital",
          "divergingStatus": "Status da NFS-e Divergente",
          "invoiceInfomation": "Informações da NFS-e",
          "invoicePdf": "PDF da NFS-e",
          "invoiceStuckedInProcessingState": "NFS-e em Estágio de Processamento",
          "issueFailed": "Falha ao Emitir",
          "others": "Outros"
        },
        "categories": {
          "cancellation": "Cancelamento",
          "doubt": "Dúvida",
          "problem": "Problema"
        },
        "dataQuerySubtypes": {
          "others": "Outros",
          "queryFailed": "Falha na Consulta",
          "queryResponse": "Dados de retorno da consulta"
        },
        "departments": {
          "comercial": "Comercial",
          "financial": "Financeiro",
          "others": "Outros",
          "technical": "Técnico"
        },
        "financialProblemSubtypes": {
          "accessBlock": "Bloqueio do Acesso",
          "cancellation": "Cancelamento de contrato",
          "changeTicketExpirationDate": "Alteração Data de Vencimento Boleto",
          "fine": "Multa e Juros",
          "generateTicketCopy": "Geração 2ª via",
          "invoiceInfo": "Informação da Fatura",
          "missingInvoice": "Falta a Nota Fiscal",
          "paymentSlip": "Envio de Boleto",
          "refund": "Estorno e Reembolso"
        },
        "fiscalSubtypes": {
          "cityServiceCodeRegistration": "Cadastramento Codigo de Serviço",
          "fiscalDoubt": "Duvida Fiscal",
          "fiscalFailure": "Erro na Emissão por Falha Fiscal",
          "others": "Outros",
          "prefectureIntegration": "Integração de Prefeitura",
          "taxCalculation": "Calculo de Imposto"
        },
        "integrationSubtypes": {
          "WooCommerce": "WooCommerce",
          "batchPlataformIssue": "Emissão em lote via planilha pela plataforma",
          "customerIntegration": "Integração própria",
          "gestaoDs": "Gestão DS - Sistema",
          "internIntegration": "Integração da NFE.io",
          "mundipagg": "Mundipagg",
          "pluga": "Pluga",
          "vindi": "Vindi",
          "whmcs": "WHMCS"
        },
        "queryParameterTypes": {
          "accessKey": "Chave de acesso",
          "legalPerson": "CNPJ",
          "naturalPerson": "CPF"
        },
        "submitMessages": {
          "error": "Error ao enviar o ticket!",
          "sending": "Enviando o ticket",
          "success": "Ticket enviado com sucesso!"
        },
        "technicalProblemSubtypes": {
          "fiscal": "Fiscal",
          "integration": "Integrações",
          "product": "Produto"
        }
      }
    },
    "environment": "Ambiente",
    "error": {
      "apikey": {
        "text": "Caro cliente, por favor entrar em contato com o suporte da NFE.IO por e-mail <a href='mailto:suporte.nfe.io'>suporte.nfe.io</a> ou telefone <a href='tel:+55114063-8091'>+55 11 4063-8091</a>",
        "title": "Detectamos algo de errado com sua conta"
      },
      "failToLoadData": {
        "description": "Ocorreu um erro ao carregar os dados, utilize o botão para entrar em contato com o nosso suporte.",
        "title": "Erro ao carregar dados!"
      },
      "serviceUnavailable": "Serviço indisponível! Tente novamente em breve."
    },
    "exitBtn": "Sair",
    "export": "Exportar",
    "finish": "Finalizar",
    "forms": {
      "account": {
        "email": "Email",
        "id": "Código de Identificação (ID)",
        "id_hint": "Se você entrar em contato com nossa equipe, usaremos seu ID para identificar sua conta e ajudá-lo em qualquer necessidade.",
        "name": "Nome"
      },
      "address": {
        "additionalInformation": "Complemento",
        "city": {
          "label": "Cidade",
          "searchPlaceholder": "Pesquise a Cidade"
        },
        "district": "Bairro",
        "header": "Dados do Endereço",
        "number": "Número",
        "postalCode": "CEP",
        "state": {
          "label": "Estado",
          "searchPlaceholder": "Pesquise o Estado"
        },
        "street": "Logradouro",
        "type": {
          "label": "Tipo",
          "searchPlaceholder": "Tipo de nota"
        }
      },
      "certificate": {
        "extensionInfo": "Arquivo do certificado com extensão: PFX ou P12",
        "password": "Senha do certificado digital",
        "selectFileBtn": "Escolher arquivo",
        "sendFileBtn": "Enviar certificado digital"
      },
      "company": {
        "federalTaxNumber": "CNPJ",
        "header": "Dados da Empresa",
        "legalNature": {
          "label": "Natureza Jurídica",
          "searchPlaceholder": "Pesquise a Natureza Jurídica"
        },
        "loginName": "Usuário de acesso ao Webservice",
        "loginPassword": "Senha de acesso ao Webservice",
        "name": "Razão Social",
        "tax": {
          "federalTaxDetermination": {
            "label": "Determinação dos impostos pela federação",
            "searchPlaceholder": "Pesquise o tipo de incidência"
          },
          "header": "Dados Tributários",
          "isExempt": "É isento",
          "municipalTaxDetermination": {
            "label": "Determinação dos impostos pelo município",
            "searchPlaceholder": "Pesquise o tipo de incidência"
          },
          "municipalTaxNumber": "Inscrição Municipal",
          "municipalTaxNumberHint": "Este é o código que identifica a empresa na cidade/prefeitura e possui vínculo com o ISS, <a href='https://nfe.io/docs/nota-fiscal-servico/conceitos-nfs-e/#o-que-e-inscric%C3%A3o-municipal' target='_blank'>saiba mais!</a>",
          "passwordHint": "Esta é a senha que autentica a empresa no sistema da prefeitura para emissão das notas fiscais.",
          "specialTaxRegime": {
            "hint": "É uma forma de tributação, onde determinado setor ou até atividade empresarial tem uma forma diferenciada de aplicação tributária.",
            "label": "Regime Especial de Tributação",
            "searchPlaceholder": "Pesquise o Regime Especial de Tributação"
          },
          "stateTaxNumber": "Inscrição Estadual",
          "stateTaxNumberHint": "Este é o código que identifica a empresa como apta a realizar operações de venda de produtos no território nacional, <a href='https://nfe.io/docs/nota-fiscal-eletronica/conceitos-nf-e/#o-que-e-inscric%C3%A3o-estadual' target='_blank'>saiba mais!</a>",
          "taxRegime": {
            "hint": "É o conjunto de leis que regulamenta a forma de tributação da pessoa jurídica/empresa.",
            "label": "Regime Tributário",
            "searchPlaceholder": "Pesquise o Regime Tributário"
          }
        },
        "tradeName": "Nome Fantasia"
      },
      "cte": {
        "title": "Consulta CT-e {action, select, activate {ativada} deactivate {desativada}}"
      },
      "dfe": {
        "automaticManifesting": "Tempo para enviar a ciência da operação",
        "automaticManifestingHint": "Tempo a ser aguardado para enviar o evento de ciência da operação para uma nfe a partir da sua captura.",
        "startFromDate": "Data inicial para pesquisa",
        "startFromDateHint": "Consulta documentos a partir da data selecionada. Obs: período máx. de 90 dias.",
        "startFromNsu": "Número inicial do NSU",
        "startFromNsuHint": "Consulta a partir do NSU informado. Se o valor informado for 0, será considerado informações dos ultimos 90 dias.",
        "switchLabel": "Envio automático do evento de ciência da operação",
        "title": "Consulta de NF-e {action, select, activate {ativada} deactivate {desativada}}"
      },
      "fiscal": {
        "rps": {
          "header": "Dados do RPS",
          "number": "Número do RPS",
          "serialNumber": "Série do RPS"
        },
        "taxes": {
          "authIssueValue": "Identificação de autorização fiscal",
          "federalTaxDetermination": "Determinação dos impostos pela federação",
          "header": "Dados de Impostos",
          "iss": "Alíquota ISS para Simples Nacional",
          "municipalTaxDetermination": "Determinação dos impostos pelo município"
        }
      },
      "hook": {
        "events": {
          "eventType": {
            "ProductInvoice": "Nota Fiscal de Produto",
            "serviceInvoice": "Nota Fiscal de Serviço"
          },
          "eventsOptions": {
            "cancel": "Cancelada",
            "issue": "Emitida",
            "pull": "Extraída"
          },
          "label": "Quais eventos você gostaria de monitorar?"
        },
        "secret": "Senha para autenticação da mensagem (HMAC)",
        "statusOptions": {
          "active": "Ativo",
          "inactive": "Inativo"
        },
        "url": "Endereço (URL)"
      },
      "stateTaxNumber": {
        "header": "Número da NFe",
        "number": "Número da nota",
        "serie": "Série"
      },
      "stateTaxSecurityCredential": {
        "code": "Código de Segurança",
        "header": "Segurança do Contribuinte",
        "id": "Identificador do Token"
      }
    },
    "goToPanel": "Ir para o painel",
    "header": {
      "helpText": "Envie um email para <a href='mailto:suporte@nfe.io'>suporte@nfe.io</a>.<br>Se preferir entre em contato <a href='tel:+551140638091'>(11) 4063-8091</a>.",
      "helpTooltip": "Precisando de ajuda?",
      "manageAccounts": "Gerenciar Contas",
      "manageUser": "Gerenciar Usuário",
      "managingAccount": "Gerenciando conta",
      "managingAccountAs": "Gerenciando conta como",
      "nfsFirstSteps": "Primeiros passos NFSe",
      "queries": "Consultas",
      "query": "Consulta de {name}",
      "versionSelector": "Alterar para versão anterior",
      "viewingWhat": "Mostrando {gender, select, male {todos os} female {todas as}} {entity}."
    },
    "hooks": {
      "data": "Dados do webhook",
      "delete": {
        "error": "Erro ao excluir webhook",
        "success": "Webhook excluído com sucesso!"
      },
      "edit": {
        "modal": {
          "header": "Excluir Webhook?",
          "text": "<p>Esta operação irá excluir definitivamente o webhook.</p><p><strong>Você tem certeza que deseja continuar?</strong></p>"
        },
        "success": "Webhook alterado com sucesso!",
        "title": "Informações do Webhook"
      },
      "error": {
        "accessibleUrl": "Não foi possível validar a URL. Verifique se a URL é acessível.",
        "alreadyExists": "Webhook já existe",
        "default": "Não conseguimos testar a sua url. Nosso teste espera que o seu servidor retorne um status http entre 200 e 299.",
        "invalidSchema": "A URL do Webhook deve ser absoluta com um esquema de 'http' ou 'https'",
        "invalidUri": "URL inválida: O nome do host não pôde ser analisado.",
        "validUrl": "Por favor verifique se a URL é válida e que o endpoint é acessível."
      },
      "filters": {
        "consumer_invoice-cancelled_error": "Erro no cancelamento",
        "consumer_invoice-cancelled_failed": "Falha no cancelamento",
        "consumer_invoice-cancelled_successfully": "Cancelada com sucesso",
        "consumer_invoice-issued_error": "Erro na emissão",
        "consumer_invoice-issued_failed": "Falha na emissão",
        "consumer_invoice-issued_successfully": "Emitida com sucesso",
        "product_invoice-cancelled_error": "Erro no cancelamento",
        "product_invoice-cancelled_failed": "Falha no cancelamento",
        "product_invoice-cancelled_successfully": "Cancelada com sucesso",
        "product_invoice-cce_error": "Erro na carta de correção",
        "product_invoice-cce_failed": "Falha na carta de correção",
        "product_invoice-cce_successfully": "Carta de correção emitida com sucesso",
        "product_invoice-issued_error": "Erro na emissão",
        "product_invoice-issued_failed": "Falha na emissão",
        "product_invoice-issued_successfully": "Emitida com sucesso",
        "product_invoice_inbound-event_raised_successfully": "Evento de NF-e gerado com sucesso",
        "product_invoice_inbound-issued_successfully": "Emitida com sucesso",
        "product_invoice_inbound_summary-event_raised_successfully": "Evento de resumo de NF-e gerado com sucesso",
        "product_invoice_inbound_summary-issued_successfully": "Resumo de NF-e emitido com sucesso",
        "product_tax-created_successfully": "Cadastrado com sucesso",
        "product_tax-creation_failed": "Falha no cadastro",
        "product_tax-custom_rules_requested": "Regra tributária requisitada",
        "tax_payment_form-created_successfully": "GNRE emitida com sucesso",
        "tax_payment_form-creation_failed": "Falha na emissão da GNRE",
        "tax_payment_form-creation_not_needed": "GNRE não necessária",
        "transportation_invoice_inbound-event_raised_successfully": "Evento de CT-e gerado com sucesso",
        "transportation_invoice_inbound-issued_successfully": "Emitido com sucesso"
      },
      "new": {
        "fillFields": "Preencha os dados do webhook",
        "selectType": "Selecione o tipo do webhook",
        "success": "Webhook cadastrado com sucesso!",
        "title": "Criar Webhook"
      },
      "type": {
        "cteDist": "CT-e distribuição",
        "gnre": "GNRE",
        "nfce": "NFC-e emissão",
        "nfe": "NF-e emissão",
        "nfeDist": "NF-e distribuição",
        "nfse": "NFS-e emissão",
        "product": "Produto"
      }
    },
    "import": "Importar",
    "inactive": "Inativo",
    "invoice": "Fatura",
    "invoices": "Notas fiscais",
    "issue": "Emitir",
    "language": "Idioma",
    "languages": {
      "english": "English",
      "portuguese": "Português"
    },
    "legalNature": "Natureza Jurídica",
    "loading": "Carregando...",
    "login": {
      "signInBtn": "Entrar",
      "subtitle": "Ir para o Painel",
      "title": "Login"
    },
    "message": "Mensagem",
    "municipalTaxNumber": "Inscrição Municipal",
    "name": "Nome",
    "nextBtn": "Avançar",
    "nfe": {
      "batch": {
        "generateBothBtn": "Gerar lote DANFE + XML",
        "generatePdfBtn": "Gerar lote DANFE",
        "generateXmlBtn": "Gerar lote XML",
        "invalid": "Invalidas",
        "title": "Gerar lote de consulta",
        "total": "Total",
        "uploadBtn": "Enviar novo lote",
        "valid": "Válidas"
      },
      "batchesList": {
        "actions": {
          "downloadPdf": "Baixar PDF",
          "downloadXml": "Baixar XML"
        },
        "flowStatus": {
          "Completed": "Processado",
          "Error": "Falha ao consultar",
          "Failed": "Falha ao consultar",
          "Pending": "Aguardando",
          "Running": "Processando",
          "Running_Zip": "Processando"
        },
        "tableHeader": {
          "date": "Modificado há",
          "name": "Nome",
          "newBatch": "Novo lote",
          "quantity": {
            "total": "N°. de Chaves",
            "totalError": "Erro",
            "totalOk": "Ok"
          },
          "source": "Fonte",
          "status": "Status",
          "timeSpent": "Tempo gasto"
        }
      },
      "deleteBatch": {
        "error": "Erro ao excluir o lote",
        "success": "Lote excluído com sucesso!",
        "text": "<p>Está ação irá excluir definitivamente o lote.</p><p><b>Você tem certeza que deseja continuar?</b></p>",
        "title": "Excluir lote?"
      },
      "devAccount": {
        "text": "Você precisa ter uma conta ativa para que possa fazer as consultas, para isso você precisa falar com o time de vendas. <a href='mailto:comercial@nfe.io'>comercial@nfe.io</a> ou <a href='tel:+551140638091'>(11) 4063-8091</a>.",
        "title": "Sua conta não foi ativada para consultas!"
      },
      "downloadBatch": {
        "error": "Erro ao baixar o lote"
      },
      "emptyList": {
        "text": "Clique no botão \"Gerar lote\" para começar a consultar notas fiscais em lote utilizando apenas a chave de acesso das notas. Aqui você poderá acompanhar o status de todos os lotes enviados para consulta, e caso o lote seja processado com sucesso, o mesmo estará disponível para download.",
        "title": "Nenhum lote gerado"
      },
      "generateBatch": {
        "batchInfo": "Informações do lote",
        "batchName": "Nome do lote",
        "batchNameValidation": "Insira um nome para o lote",
        "chooseBatchName": "Escolha um nome para o lote de saída",
        "invalidKeys": "Chaves inválidas:",
        "keysInfo": "Informações das chaves",
        "sendBatch": "Enviar lote",
        "sendBatchError": "Erro ao enviar lote",
        "sendBatchSuccess": "Lote enviado com sucesso!",
        "validKeys": "Chaves válidas:"
      },
      "header": "Consulta de NFe",
      "subHeader": "Lotes",
      "subTitle": "Notas Fiscais Eletrônicas",
      "title": "Gerenciamento de consultas de NFe",
      "uploadBatch": {
        "copyPaste": "Copie e cole as chaves de acesso no campo abaixo",
        "dragDropText": "Arraste ou clique para selecionar o arquivo",
        "exampleExcel": "Exemplo excel",
        "exampleText": "Exemplo texto",
        "hasExceededLimit": "Limite de chaves excedido (N° de chaves: {limit} Limite: 3000)",
        "loadFile": "Carregue um arquivo de excel(.xlsx) ou texto(.txt)",
        "placeholder": "Chaves de acesso",
        "step": "Etapa",
        "validateKeys": "Validar Chaves"
      }
    },
    "no": "Não",
    "number": "Número",
    "okBtn": "OK",
    "onboard": {
      "activateErrorMsg": "Erro ao ativar serviços. Tente novamente.",
      "cteInbound": {
        "content": "Consulta de CT-e e eventos do CT-e para os atores envolvidos na operação utilizando certificado digital tipo A1.",
        "title": "Consulta de CT-e distribuição"
      },
      "legalEntityQuery": {
        "content": "Consulte dados de pessoa jurídica utilizando apenas o número do CNPJ, tais como: dados básicos, simples nacional e inscrição estadual.",
        "title": "Consulta de Pessoa Jurídica"
      },
      "multiCompanies": {
        "content": "Crie e gerencie mútiplas empresas em sua conta. Esse serviço funciona necessariamente em conjunto com o serviço de emissão de NFe ou NFSe.",
        "title": "Mútiplas empresas"
      },
      "naturalPersonQuery": {
        "content": "Consulte dados de pessoa física utilizando apenas o número do CPF e tenha acesso à situação cadastral do mesmo.",
        "title": "Consulta de Pessoa Física"
      },
      "nfceIssuance": {
        "content": "Emita Notas Fiscais de Consumidor; Consulte e acompanhe os eventos de suas notas; Baixe PDF e XML.",
        "title": "Emissão de Nota Fiscal de Consumidor"
      },
      "nfeInbound": {
        "content": "Consulta de NF-e e eventos da NF-e para destinatários e/ou interessados utilizando certificado digital tipo A1.",
        "title": "Consulta de NF-e distribuição"
      },
      "nfeIssuance": {
        "content": "Emita Notas Fiscais de Produto; Consulte e acompanhe os eventos de suas notas; Baixe PDF e XML.",
        "title": "Emissão de Nota Fiscal de Produto"
      },
      "nfeQuery": {
        "content": "Consulte Notas Fiscais eletrônicas em lote apenas com as chaves de acesso via arquivo de excel, texto ou colando as chaves.",
        "title": "Consulta de Nota Fiscal de Produto"
      },
      "nfseIssuance": {
        "content": "Emita Notas Fiscais de Serviço; Consulte e acompanhe o processamento de suas notas; Baixe PDF e XML.",
        "title": "Emissão de Nota Fiscal de Serviço"
      },
      "products": {
        "dataTech": "Serviços para consultas",
        "dfeTech": "Serviços para emissão de notas fiscais"
      },
      "serviceInvoice": {
        "firstSteps": {
          "createCompany": {
            "text": "Cadastre os dados da empresa que irá emitir nfse na plataforma.",
            "title": "Cadastrar Empresa"
          },
          "issueInvoice": {
            "text": "Emita sua primeira nota fiscal de teste na plataforma.",
            "title": "Testar emissão"
          },
          "title": "Primeiros passos para emitir uma Nota Fiscal de Serviço",
          "uploadCertificate": {
            "text": "Faça o upload do seu certificado digital na plataforma.",
            "title": "Upload do Certificado"
          }
        },
        "issueStepper": {
          "cancelModal": {
            "text": "Ao confirmar, iremos cancelar a nota fiscal de serviço emitida na etapa anterior.",
            "title": "Confirmar cancelamento?"
          },
          "issueModal": {
            "text": "Ao confirmar, iremos emitir a nota fiscal de serviço com as informações fornecidas.",
            "title": "Confirmar emissão?"
          },
          "step1": {
            "message": "Entre em contato com o seu contador e faça sua primeira emissão de nota fiscal de serviço para utilizá-la como base para as próximas emissões.",
            "title": "Você já emitiu uma nota fiscal de serviço antes?"
          },
          "step2": {
            "text1": "É importante emitir a primeira nota fiscal na sua prefeitura, já em ambiente de produção. Não se preocupe, o valor da nota será de 10 centavos e nós da NFE.io seremos o tomador do serviço. Assim, conseguiremos validar se todos os dados cadastrados estão corretos.",
            "text2": "É importante emitir a primeira nota fiscal para a validação dos dados cadastrados de sua empresa. Não se preocupe, será uma nota de teste no valor de 10 centavos e nós da NFE.io seremos o tomador do serviço.",
            "title": "Vamos emitir sua primeira nota com a NFE.io?"
          },
          "step3": {
            "text": "Utilize a barra de pesquisa para encontrar o código do serviço prestado pela sua empresa. Usaremos esse código para a emissão da nota. Veja abaixo a lista de serviços cadastrados em {cityName}, {state}. Obs: é possível selecionar apenas um código.",
            "title": "Pesquise e selecione o serviço da sua nota fiscal"
          },
          "step4": {
            "borrowerData": "Dados do tomador",
            "issuerData": "Dados do emissor",
            "nfseData": "Dados da NFSe",
            "serviceDescription": "Emissão de nota fiscal de serviço em ambiente de {environment} para validação dos dados cadastrados.",
            "text1": "Revise os dados abaixo para realizar a emissão da nota fiscal na sua prefeitura já em <b>ambiente de produção</b>. Lembrando que o emissor será sua empresa cadastrada anteriormente e o tomador será a empresa NFE.io.",
            "text2": "Revise os dados abaixo para realizar a emissão da nota fiscal na <b>prefeitura de teste</b> da NFE.io. Lembrando que o emissor será sua empresa cadastrada anteriormente e o tomador será a empresa NFE.io.",
            "title": "Confirmar dados de emissão",
            "totalAmount": "Valor total"
          },
          "step5": {
            "calculatingTaxes": "Calculando impostos",
            "definingRpsNumber": "Definindo número de RPS",
            "sendingToNfeio": "Enviando para o sistema da NFE.io",
            "sendingToPrefecture": "{isCompanyInProduction, select, true {Enviando para o sistema da prefeitura} false {Enviando para a prefeitura de teste da NFE.io}}",
            "text": "Nessa etapa iremos emitir uma nota fiscal de serviço com os dados confirmados na etapa anterior.<br>Acompanhe o envio da nota abaixo.",
            "title": "Emitir nota fiscal de serviço",
            "waitingReturn": "{isCompanyInProduction, select, true {Aguardando retorno do sistema da prefeitura} false {Aguardando retorno da prefeitura de teste}}"
          },
          "step6": {
            "errorMessage": "Ocorreu um erro na emissão.<br><b>Mensagem:</b> {message}",
            "successMessage": "Parabéns! Emitimos com sucesso uma nota fiscal na prefeitura de teste da NFE.io. Visualize sua nota realizando o download do PDF/XML e valide se todas as informações estão corretas.",
            "titleDefault": "Visualizar nota",
            "titleError": "Falha ao emitir nota fiscal de serviço",
            "titleSuccess": "Nota fiscal de serviço emitida com sucesso!"
          },
          "step7": {
            "sendingToNfeio": "Enviando solicitação para o sistema da NFE.io",
            "sendingToPrefecture": "Enviando cancelamento para o sistema da prefeitura",
            "text": "Nessa etapa iremos cancelar a nota fiscal de serviço emitida etapa anterior. Clique no botão abaixo para realizar o cancelamento.<br>Acompanhe o cancelamento da nota abaixo.",
            "title": "Cancelar nota fiscal de serviço",
            "waitingReturn": "{isCompanyInProduction, select, true {Aguardando retorno do sistema da prefeitura} false {Aguardando cancelamento na prefeitura de teste}}"
          },
          "step8": {
            "errorMessage": "Ocorreu um erro no cancelamento.<br><b>Mensagem:</b> {message}",
            "successMessage": "Parabéns! Sua nota fiscal de serviço foi cancelada com sucesso.<br>Visualize sua nota realizando o download do PDF/XML.",
            "titleDefault": "Visualizar nota",
            "titleError": "Falha ao cancelar nota fiscal de serviço",
            "titleSuccess": "Nota fiscal de serviço cancelada com sucesso!"
          },
          "title": "Emitir primeira nota fiscal",
          "waiting": {
            "message1": "Por favor, aguarde um instante!",
            "message2": "Estamos processando sua nota fiscal, aguarde.",
            "message3": {
              "waitingCalculateTaxes": "Geralmente essa etapa costuma ser mais rápida. Pode ser que nosso sistema esteja instável no momento, gostaria de voltar mais tarde?",
              "waitingDownload": "Geralmente essa etapa costuma ser mais rápida. Pode ser que nosso sistema esteja instável no momento, gostaria de voltar mais tarde?",
              "waitingReturn": "As prefeituras podem apresentar lentidão e instabilidade com certa frequência e esta etapa poderá demorar um pouco mais que o esperado, gostaria de voltar mais tarde?",
              "waitingSend": "Estamos com um número alto de notas em nossa fila e esta etapa poderá demorar um pouco mais que o esperado, gostaria de voltar mais tarde?"
            },
            "modalTitle": "Desculpe a demora"
          }
        },
        "skipOnboardModal": {
          "text": "Recomendamos realizar os primeiros passos para que possamos validar corretamente todas as informações necessárias para a emissão de uma nota fiscal de serviço.",
          "title": "Deseja realmente pular essa etapa?"
        },
        "successMessage": {
          "api": {
            "text": "Integração com nossa API (requer uma equipe de desenvolvimento).",
            "title": "API"
          },
          "batchByExcel": {
            "text": "Emissão de notas fiscais em lote através de uma planilha de excel.",
            "title": "Lote por excel"
          },
          "integrations": {
            "text": "Integração com sistemas de pagamentos (Ex: Vindi, Iugu).",
            "title": "Integrações"
          },
          "startIssuing": "Comece agora a emitir suas notas fiscais de serviço",
          "text": "Você emitiu sua primeira nota fiscal em sua empresa. Se ainda tiver dúvidas, sugerimos que você comece aprendendo<br><a href='https://nfe.io/docs/nota-fiscal-servico/conceitos-nfs-e/' target='_blank'>tudo sobre Nota Fiscal de Serviço</a>. Abaixo temos algumas opções de como você pode emitir notas fiscais utilizando nosso sistema.",
          "title": "Parabéns! Você completou todas as etapas para a emissão de notas fiscais de serviço"
        }
      },
      "text": "Sua conta foi criada com sucesso! Para continuar, selecione abaixo os serviços que deseja utilizar em nossa plataforma.",
      "title": "Selecione os serviços que deseja utilizar em nossa plataforma"
    },
    "openTicket": "Abrir Ticket",
    "password": "Senha",
    "pay": "Pagar",
    "payment": {
      "addNewCard": "Adicionar novo cartão",
      "bankSlip": "Boleto",
      "bankTransferModal": {
        "account": "Conta",
        "selectBank": "Selecione o banco que deseja realizar a transferência",
        "title": "Transferência bancária"
      },
      "creditCard": "Cartão de Crédito",
      "creditCardForm": {
        "expiration": "MM / AA",
        "holderName": "Nome (igual no cartão)",
        "number": "Número do cartão",
        "securityCode": "CVV"
      },
      "creditCardModal": {
        "failedTitle": "Falha no pagamento",
        "internalServerError": {
          "text": "Nosso sistema de pagamentos está indisponível. Entre em contato com o nosso suporte (11) 4063-8091.",
          "title": "Não foi possível realizar o pagamento"
        },
        "processingTitle": "Aguarde enquanto processamos o seu pagamento...",
        "successTitle": "Pagamento aprovado!",
        "unauthorized": {
          "text": "Por favor, verifique seus dados ou entre em contato com sua operadora de cartão.",
          "title": "Pagamento recusado"
        }
      },
      "customer": {
        "title": "Dados do Cliente"
      },
      "discount": "Desconto",
      "federalTaxNumber": "CPF/CNPJ",
      "invoice": {
        "createdOn": "Competência em",
        "downloadEInvoice": "Baixar Nota Fiscal",
        "downloadEInvoiceXML": "Baixar Nota Fiscal XML",
        "downloadInvoice": "Baixar Fatura",
        "dueOn": "Vencimento em"
      },
      "payInvoice": "Pagar agora",
      "penalty": "Multa/Juros",
      "pixModal": {
        "text": "Procure em seu aplicativo de banco ou conta digital a opção de pagamento via PIX e escaneie o QR Code acima."
      },
      "provider": {
        "title": "Dados do Prestador"
      },
      "status": {
        "Cancelled": "Cancelado",
        "Expired": "Expirado",
        "New": "Novo",
        "Paid": "Pago",
        "PartiallyPaid": "Parcialmente pago",
        "Pending": "Pendente",
        "Refunded": "Estornado",
        "WaitingGateway": "Aguardando Gateway"
      },
      "subtitle": "<small>#{paymentNumber}</small>",
      "taxWithheld": "Imposto Retido",
      "title": "Pagamento"
    },
    "postalCode": "CEP",
    "preInvoice": {
      "approvalModal": {
        "confirmation": "Deseja realmente aprovar esta Pré-Fatura?",
        "title": "Aprovar Pré-Fatura"
      },
      "customer": {
        "title": "Dados do Cliente"
      },
      "discount": "Desconto",
      "federalTaxNumber": "CPF/CNPJ",
      "invoice": {
        "downloadInvoice": "Baixar Pré-Fatura",
        "dueOn": "Vencimento em",
        "effectiveOn": "Competência em",
        "title": "Detalhes da Pré-Fatura",
        "usageUpdatedOn": "Uso atualizado em"
      },
      "provider": {
        "title": "Dados do Prestador"
      },
      "refusalModal": {
        "confirmation": "Nos informe o motivo pelo qual está recusando esta Pré-Fatura.",
        "title": "Recusar Pré-Fatura"
      },
      "status": {
        "Approved": "Aprovada",
        "Declined": "Reprovada",
        "Done": "Faturada",
        "Draft": "Nova",
        "Executing": "Em andamento",
        "Refused": "Recusada",
        "WaitingApproval": "Aguardando",
        "WaitingBilling": "Faturando",
        "unknown": "Desconhecido"
      },
      "subtitle": "<small>#{orderNumber}</small>",
      "taxes": "Impostos",
      "title": "Pré-Fatura"
    },
    "productInvoices": "Notas Fiscais de Produto",
    "provider": "Prestador",
    "quantity": "Quantidade",
    "quantityAbbr": "Qtd",
    "refuse": "Recusar",
    "register": {
      "company": "Empresa",
      "name": "Nome",
      "passwordMessage": "Use 8 ou mais caracteres com uma combinação de letras, números e símbolos",
      "phone": "Telefone",
      "signInBtn": "Faça login em vez disso",
      "subtitle": "para continuar e acessar o Painel",
      "title": "Crie sua Conta"
    },
    "registerBtn": "Cadastrar",
    "reload": "Atualizar",
    "remove": "Remover",
    "removeBtn": "Excluir",
    "reprocessPdf": "Reprocessar PDF",
    "reprocessWebhook": "Reprocessar Webhook",
    "returnBtn": "Voltar",
    "select": "Selecionar",
    "selected": "Selecionado",
    "sendEmail": "Enviar email",
    "sendFile": "Enviar Arquivo",
    "sequence": "Sequência",
    "serie": "Série",
    "serviceInvoices": "Notas Fiscais de Serviço",
    "show": "Visualizar",
    "signupBtn": "Cadastre-se",
    "skipBtn": "Pular",
    "specialTaxRegime": "Regime Especial de Tributação",
    "start": "Começar",
    "stateTax": "Inscrição Estadual",
    "submitBtn": "Salvar",
    "table": {
      "buyer": {
        "name": "Comprador"
      },
      "certificateStatus": "Certificado Digital",
      "createdOn": "Criado em",
      "description": "Descrição",
      "email": "Email",
      "environment": "Ambiente",
      "federalTaxNumber": "CNPJ",
      "modifiedDate": "Modificado",
      "name": "Razão Social",
      "nextPage": "Ir para a próxima página",
      "page": "Página",
      "prevPage": "Ir para a página anterior",
      "rowsPerPage": "Linhas por página",
      "search": "Adicionar um Filtro",
      "state": "Cidade/UF",
      "status": "Status",
      "tableBody": {
        "status": {
          "Active": "Ativo",
          "Inactive": "Inativo"
        }
      },
      "type": "Tipo",
      "url": "URL"
    },
    "taxRegime": "Regime Tributário",
    "ticketDialog": {
      "steps": {
        "advancedData": {
          "formTitle": "Dados avançados",
          "inputs": {
            "company": {
              "hint": "Selecione a empresa emitente da nota fiscal",
              "label": "Empresa",
              "searchPlaceholder": "Selecione uma empresa"
            },
            "invoiceId": {
              "hint": "Insira o ID na nota fiscal em que ocorreu o erro",
              "label": "ID da Nota Fiscal"
            },
            "naturalPersonbirthDate": {
              "label": "Data de nascimento",
              "placeholder": "Data de nascimento"
            },
            "problemSubType": {
              "hint": "Selecione a opção que mais se aproxima do problema",
              "label": "Subtipo do problema",
              "searchPlaceholder": "Selecione uma opção"
            },
            "queryDate": {
              "hint": "Insira o dia em que foi feita a consulta (formato: AAAA-MM-DD)",
              "label": "Data da consulta",
              "placeholder": "Data da consulta"
            },
            "queryParameter": {
              "hint": "Insira o parâmetro da consulta",
              "label": "Parâmetro da consulta"
            }
          },
          "label": "Dados do produto"
        },
        "basicData": {
          "formTitle": "Dados básicos",
          "inputs": {
            "category": {
              "label": "Categoria",
              "searchPlaceholder": "Selecione a categoria"
            },
            "department": {
              "hint": "Selecione o Setor da NFE.io que você acredita ser responsável pelo seu tipo de problema/dúvida",
              "label": "Setor reponsável",
              "searchPlaceholder": "Selecione uma opção"
            },
            "product": {
              "hint": "Selecione o produto onde surgiu o problema",
              "label": "Produto",
              "searchPlaceholder": "Selecione um produto"
            },
            "ticketType": {
              "hint": "Selecione a opções que mais se aproxima do seu problema/dúvida",
              "label": "Tipo do problema",
              "searchPlaceholder": "Selecione o setor responsável"
            }
          },
          "label": "Dados básicos do ticket"
        },
        "identification": {
          "formTitle": "Idenficação e descrição",
          "label": "Identifique-se e insira uma descrição",
          "sendButton": "Enviar Ticket"
        },
        "send": {
          "awaitingSendMessage": "Aguarde! Estamos enviando os dados do ticket",
          "error": {
            "failedExplanation": "Devido a alguma intermitência no nosso sistema, não foi possível abrir o seu ticket.",
            "failedToSend": "Erro ao abrir o ticket",
            "sorryMessage": "Pedimos desculpas pelo transtorno. Você pode entrar em contato conosco através do telefone (11) 4063-8091."
          },
          "label": "Status do envio",
          "sentWithSuccess": "O ticket foi enviado com sucesso!"
        },
        "uploadFiles": {
          "instructions": "Utilize esse passo para carregar arquivos que evidenciem o seu problema ou dúvida, por exemplo: Print da tela, XML ou PDF da nota fiscal",
          "label": "Upload de arquivos",
          "table": {
            "actions": "Ações",
            "fileName": "Nome do arquivo"
          },
          "uploadAdvise": "Enviar arquivos referente a sua solicitação pode agilizar a resolução do seu ticket",
          "uploadBtn": "Carregar arquivos"
        }
      },
      "title": "Abrir Ticket"
    },
    "total": "Total",
    "type": "Tipo",
    "user": "Usuário",
    "validations": {
      "email": "Digite um e-mail. Ele pode ser repetido nos outros campos de e-mail.",
      "password": "Digite uma senha",
      "register": {
        "company": "Insira no mínimo 2 caracteres",
        "name": "Digite seu nome completo",
        "phone": "Digite seu telefone",
        "validName": "Digite seu nome completo válido",
        "validPhone": "Digite um telefone válido"
      },
      "validEmail": "Digite um e-mail válido",
      "validPassword": "Digite uma senha válida (mínimo 8 digitos)"
    },
    "version": "Versão",
    "yes": "Sim"
  };
  _exports.default = _default;
});