define("nfe-customer-console-v2/components/company-form/component", ["exports", "nfe-customer-console-v2/models/enums", "nfe-customer-console-v2/helpers/cnpj-validator"], function (_exports, _enums, _cnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    legalNatures: _enums.default.legalNatures,
    taxRegimes: _enums.default.taxRegimes,
    selectedLegalNature: Ember.computed('model.legalNature', function () {
      const value = this.get('model.legalNature');

      if (value === 0) {
        return _enums.default.legalNatures[0];
      } else {
        return _enums.default.legalNatures.filter(v => v.code === value)[0];
      }
    }),
    selectedTaxRegime: Ember.computed('model.taxRegime', function () {
      const value = this.get('model.taxRegime');

      if (value === 0) {
        return _enums.default.taxRegimes[0];
      } else {
        return _enums.default.taxRegimes.filter(v => v.code === value)[0];
      }
    }),
    addressAutoFill: Ember.computed(function () {
      return Ember.isPresent(this.model.address.postalCode);
    }),
    getCnpj: Ember.observer('model.federalTaxNumber', async function () {
      const taxNumber = this.get('model.federalTaxNumber');
      if ((0, _cnpjValidator.cnpjValidator)(taxNumber) === false) return;
      const companyAdapter = this.store.adapterFor('company');
      const company = await companyAdapter.getCompanyByFederalTaxNumber(taxNumber);
      const {
        name,
        tradeName,
        legalNature,
        address
      } = company;

      const filteredLegalNature = _enums.default.legalNatures.filter(v => v.id === legalNature.code)[0];

      this.model.setProperties({
        name,
        tradeName,
        address,
        legalNature: filteredLegalNature && filteredLegalNature.hasOwnProperty("code") ? filteredLegalNature.code : null
      });
    }),
    actions: {
      submit() {
        this.sendAction('submit');
      }

    }
  });

  _exports.default = _default;
});